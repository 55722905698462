import { useEffect, useRef, useState } from "react";
import { Ama } from "../../models/ama.model";
import PlaceHolderImage from "./PlaceHolderImage";
import ImageGallery from "react-image-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function AAAImageView({
  aaa,
  c,
}: {
  aaa: Ama;
  c: HTMLDivElement | null;
}) {
  console.log(aaa);

  const [imgUrls, setImgUrl] = useState<string[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [full, setFull] = useState<boolean>(false);

  useEffect(() => {
    if (aaa.embedded_media) {
      setImgUrl(
        aaa.embedded_media.map((e) => {
          return e.url;
        })
      );
    }
  }, [aaa.embedded_media]);

  switch (aaa.embedded_media?.length) {
    case 0:
      return <div></div>;
    // case 1:
    //   let media = aaa.embedded_media[0]!;
    //   let width = parseFloat(media.width);
    //   let height = parseFloat(media.height);
    //   let maxH = (width / 9) * 16;
    //   let greaterThan9_16 = height > maxH;
    //   let windowW = c?.clientWidth;

    //   return (
    //     <div className="mt-[6px]">
    //       {greaterThan9_16 ? (
    //         <div className="aspect-[9/16] w-full overflow-hidden relative">
    //           <PlaceHolderImage src={media.url} type="aaa" />
    //         </div>
    //       ) : (
    //         <PlaceHolderImage src={media.url} type="aaa" />
    //       )}
    //     </div>
    //   );
    default:
      return (
        <div className="mt-[6px] grid grid-cols-3 gap-[4px] overflow-hidden w-full">
          {aaa.embedded_media!.map((media) => {
            return (
              <div className="aspect-square w-full h-full">
                <div
                  className="w-full h-full"
                  onClick={() => {
                    setFull(true);
                  }}
                >
                  <PlaceHolderImage src={media.url} type="aaa" />
                </div>
              </div>
            );
          })}
          {imgUrls.length > 0 && full && (
            <Lightbox
              mainSrc={imgUrls[index]!}
              nextSrc={imgUrls[(index + 1) % imgUrls.length]!}
              prevSrc={imgUrls[(index + imgUrls.length - 1) % imgUrls.length]!}
              onCloseRequest={() => {
                setFull(false);
              }}
              onMovePrevRequest={() =>
                setIndex((index + imgUrls.length - 1) % imgUrls.length)
              }
              onMoveNextRequest={() => setIndex((index + 1) % imgUrls.length)}
            />
          )}
        </div>
      );
  }

  // return (
  //   <div className="w-full h-fit">
  //     {aaa.embedded_media instanceof Array &&
  //       aaa.embedded_media.length > 0 && (
  //         <div className="w-full flex flex-col space-y-[6px] mt-[6px]">
  //           {aaa.embedded_media.map((media) => {
  //             let width = parseFloat(media.width);
  //             let height = parseFloat(media.height);
  //             let maxH = (width / 9) * 16;
  //             let greaterThan9_16 = height > maxH;
  //             if (greaterThan9_16) {
  //               return (
  //                 <div className="aspect-[9/16] w-full overflow-hidden relative">
  //                   <img
  //                     src={media.url}
  //                     className="w-full absolute top-0 object-cover rounded-[4px]"
  //                     alt=""
  //                   />
  //                 </div>
  //               );
  //             } else {
  //               return (
  //                 <img
  //                   src={media.url}
  //                   alt=""
  //                   className="w-full rounded-[4px]"
  //                 />
  //               );
  //             }
  //           })}
  //         </div>
  //       )}
  //   </div>
  // );
}
