import { useRef } from "react";
import { Highlight } from "../../api/AmaApi";
import link_bg from "../../assets/hightlight/link_d_bg.svg";
import PlaceHolderImage from "../AAA/PlaceHolderImage";
import classNames from "classnames";
import { HLDetialLinkView } from "./HighlightCardContainer";

export function LinkDetailCard({ hightlight }: { hightlight: Highlight }) {
  function getImageHight() {
    if (hightlight.aspect_ratio == "3:4") {
      return "72.88%";
    } else if (hightlight.aspect_ratio == "4:3") {
      return "23.72%";
    } else {
      return "37.29%";
    }
  }

  function getNumberImageHight() {
    if (hightlight.aspect_ratio == "3:4") {
      return 0.7288;
    } else if (hightlight.aspect_ratio == "4:3") {
      return 0.2372;
    } else {
      return 0.3729;
    }
  }

  const imageViewRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  let lastScrollTop = 0;

  return hightlight.image ? (
    <div
      ref={contentRef}
      className="flex flex-col h-full relative z-20 bg-[#FDFDFD] pb-[28px]"
    >
      <div
        className={classNames(
          "absolute h-[48px] z-[20] w-[99%] bottom-[28px] bg-gradient-to-b from-transparent left-[0.5%] to-[#FDFDFD]"
        )}
      ></div>
      <div
        ref={scrollRef}
        onScroll={(e) => {
          //   // 获取当前的滚动位置
          //   const currentScrollTop = e.currentTarget.scrollTop;
          //   // 判断滚动方向
          //   const isScrollingDown = currentScrollTop > lastScrollTop;
          //   console.log("Scroll Top:", currentScrollTop);
          //   console.log("Scrolling Down:", isScrollingDown);
          //   if (isScrollingDown) {
          //     let h = imageViewRef.current!.clientHeight;
          //     if (h >= 112) {
          //       imageViewRef.current!.style.height = `${
          //         h - currentScrollTop + lastScrollTop
          //       }px`;
          //     }
          //   }
          //   if (!isScrollingDown) {
          //     let h = imageViewRef.current!.clientHeight;
          //     if (h <= contentRef.current!.clientHeight * getNumberImageHight()) {
          //       imageViewRef.current!.style.height = `${
          //         h + lastScrollTop - currentScrollTop
          //       }px`;
          //     }
          //   }
          //   lastScrollTop = currentScrollTop;
        }}
        className="flex flex-col h-full relative overflow-y-auto overscroll-none overflow-x-hidden pb-[48px]"
      >
        <div
          ref={imageViewRef}
          style={{ height: getImageHight() }}
          className="w-full flex-shrink-0 relative rounded-[14px] overflow-hidden"
        >
          <PlaceHolderImage type="message" src={hightlight.image} />
        </div>
        <div className="mt-[12px] px-[16px] w-full flex-shrink-0">
          <HLDetialLinkView type="link" highlight={hightlight} />
        </div>
        <div
          className={classNames(
            "w-full h-fit px-[16px] pt-[4px] mt-[12px] flex-shrink-0"
          )}
        >
          <div className="font-bold flex flex-col justify-center">
            <p
              className={classNames(
                "font-bold text-[24px] leading-[120%] flex-shrink-0"
              )}
            >
              {hightlight.title}
            </p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: hightlight.description.replace(/\n/g, "<br/>"),
            }}
            className={classNames(
              "font-medium text-[14px] leading-[120%] text-[#666] mt-[12px] flex-shrink-0"
            )}
          ></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="relative flex h-full flex-col bg-[#FDFDFD] justify-between pb-[28px]">
      <div
        className={classNames(
          "absolute h-[48px] z-[20] w-[99%] bottom-[28px] bg-gradient-to-b from-transparent left-[0.5%] to-[#FDFDFD]"
        )}
      ></div>
      <img className="absolute left-0 top-0 z-0" src={link_bg} alt="" />
      <div className="relative px-[16px] flex h-full flex-col overflow-y-auto overscroll-none overflow-x-hidden pb-[48px]">
        <div className="mt-[28px] z-10">
          <HLDetialLinkView type="link" highlight={hightlight} />
        </div>
        <div className={classNames("flex grow flex-col mt-[20px] z-10")}>
          <div className={classNames("font-bold text-[24px] leading-[120%]")}>
            {hightlight.title}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: hightlight.description.replace(/\n/g, "<br/>"),
            }}
            className={classNames(
              "text-[14px] font-medium text-[#666666] leading-[120%] mt-[20px]"
            )}
          ></div>
        </div>
      </div>
    </div>
  );
}
