import copy from "copy-to-clipboard";
import { SocialLink } from "../../api/AmaApi";
import Email from "../../assets/hightlight/Email.png";
import ENS from "../../assets/hightlight/ENS.png";
import Facebook from "../../assets/hightlight/Facebook.png";
import Github from "../../assets/hightlight/Github.png";
import Instagram from "../../assets/hightlight/Instagram.png";
import Linkedln from "../../assets/hightlight/Linkedln.png";
import Medium from "../../assets/hightlight/Medium.png";
import Onlyfans from "../../assets/hightlight/Onlyfans.png";
import Paragraph from "../../assets/hightlight/Paragraph.png";
import Reddit from "../../assets/hightlight/Reddit.png";
import Snapchat from "../../assets/hightlight/Snapchat.png";
import Soundcloud from "../../assets/hightlight/Soundcloud.png";
import Spotify from "../../assets/hightlight/Spotify.png";
import Substack from "../../assets/hightlight/Substack.png";
import Telegram from "../../assets/hightlight/Telegram.png";
import Thread from "../../assets/hightlight/Thread.png";
import Tiktok from "../../assets/hightlight/Tiktok.png";
import Twitch from "../../assets/hightlight/Twitch.png";
import Wechat from "../../assets/hightlight/Wechat.png";
import Weibo from "../../assets/hightlight/Weibo.png";
import X from "../../assets/hightlight/X.png";
import Xiaohongshu from "../../assets/hightlight/Xiaohongshu.png";
import YouTube from "../../assets/hightlight/YouTube.png";
import notification from "../../util/notifiaction";
import { User } from "../../models/user.model";

export default function SocialItem({
  sociallink,
  user,
}: {
  sociallink: SocialLink;
  user?: User;
}) {
  function getSocialImage() {
    let name = sociallink.name.toLocaleLowerCase();
    if (name == "Email".toLocaleLowerCase()) {
      return Email;
    } else if (name == "X".toLocaleLowerCase()) {
      return X;
    } else if (name == "Telegram".toLocaleLowerCase()) {
      return Telegram;
    } else if (name == "Threads".toLocaleLowerCase()) {
      return Thread;
    } else if (name == "Tiktok".toLocaleLowerCase()) {
      return Tiktok;
    } else if (name == "Twitch".toLocaleLowerCase()) {
      return Twitch;
    } else if (name == "Wechat".toLocaleLowerCase()) {
      return Wechat;
    } else if (name == "Weibo".toLocaleLowerCase()) {
      return Weibo;
    } else if (name == "Xiaohongshu".toLocaleLowerCase()) {
      return Xiaohongshu;
    } else if (name == "YouTube".toLocaleLowerCase()) {
      return YouTube;
    } else if (name == "ENS".toLocaleLowerCase()) {
      return ENS;
    } else if (name == "Substack".toLocaleLowerCase()) {
      return Substack;
    } else if (name == "Spotify".toLocaleLowerCase()) {
      return Spotify;
    } else if (name == "Soundcloud".toLocaleLowerCase()) {
      return Soundcloud;
    } else if (name == "Snapchat".toLocaleLowerCase()) {
      return Snapchat;
    } else if (name == "Reddit".toLocaleLowerCase()) {
      return Reddit;
    } else if (name == "Paragraph".toLocaleLowerCase()) {
      return Paragraph;
    } else if (name == "OnlyFans".toLocaleLowerCase()) {
      return Onlyfans;
    } else if (name == "Medium".toLocaleLowerCase()) {
      return Medium;
    } else if (name == "LinkedIn".toLocaleLowerCase()) {
      return Linkedln;
    } else if (name == "Instagram".toLocaleLowerCase()) {
      return Instagram;
    } else if (name == "Github".toLocaleLowerCase()) {
      return Github;
    } else if (name == "Facebook".toLocaleLowerCase()) {
      return Facebook;
    } else {
      return Email;
    }
  }

  return (
    <button
      onClick={() => {
        let win: any = window;
        console.log("win.analytics = ", win.analytics);
        win.analytics.track("Social Link Opened", {
          sl_id: sociallink._id,
          type: sociallink.type,
          author: user,
        });

        if (
          sociallink.type.toLocaleLowerCase() == "email" ||
          sociallink.type.toLocaleLowerCase() == "wechat"
        ) {
          copy(sociallink.url);
          notification.error("Copied");
        } else {
          window.open(sociallink.url, "_blank");
        }
      }}
      className="w-[32px] h-[32px] bg-no-repeat bg-contain flex-shrink-0 snap-start"
    >
      <img src={getSocialImage()} className="w-full h-full" />
    </button>
  );
}
