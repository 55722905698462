import classNames from "classnames";
import bg from "../assets/profile_bg.svg";

import avatar from "../assets/avatar.png";

import next_page from "../assets/hightlight/next_page.svg";
import pre_page from "../assets/hightlight/pre_page.svg";
import create_aaany from "../assets/hightlight/create_aaany.png";

import Shuffle from "shufflejs";
import { useEffect, useRef, useState } from "react";
import {
  Highlight,
  SocialLink,
  getHightlights,
  getSociallinks,
  getUserInfo,
} from "../api/AmaApi";
import { useLocation, useParams } from "react-router-dom";
import { User } from "../models/user.model";
import { Toaster } from "react-hot-toast";
import branch from "branch-sdk";
import useWindowSize from "../hooks/useWindowSize";
import HighlightCardContainer from "../components/Highlight/HighlightCardContainer";
import SocialItem from "../components/Highlight/SocialItem";
import { createBranchLink } from "./portal_desktop";

export default function PortalMobile({
  segmentReady,
}: {
  segmentReady: boolean;
}) {
  let { username } = useParams();
  username = username || "aaany";

  const shuffleContainerRef = useRef<HTMLDivElement>(null);
  const sizerRef = useRef<HTMLDivElement>(null);
  const socialContentRef = useRef<HTMLDivElement>(null);
  const bioContentRef = useRef<HTMLDivElement>(null);
  const createRef = useRef<HTMLButtonElement>(null);

  const nexPageBtnRef = useRef<HTMLButtonElement>(null);

  const prePageBtnRef = useRef<HTMLButtonElement>(null);

  const [itemW, setItemW] = useState<number>(200);

  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const [user, setUser] = useState<User>();

  const [hightlights, setHightlights] = useState<[Highlight] | null>(null);

  const [socialLinks, setSocialLinks] = useState<[SocialLink] | null>(null);

  const [layoutUpdated, setLayoutUpdated] = useState(false);

  const uLocation = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(uLocation.search);
    const preview = searchParams.get("preview") as unknown as boolean;
    if (preview && createRef.current && user) {
      createRef.current.style.visibility = "hidden";
    }
  }, [uLocation, createRef, user]);

  useEffect(() => {
    const win: any = window;
  }, []);

  function toApp(button_text: string, page_name: string) {
    let win: any = window;
    var anonymous_id = undefined;
    if (win.analytics != undefined && win.analytics.user()) {
      anonymous_id = win.analytics.user().anonymousId();
      win.analytics.track("Button Clicked", {
        page_name: page_name,
        button_text: button_text,
      });
    }

    var linkData = {
      feature: "AAAny Portal",
      channel: "web",
      data: {
        anonymousId: anonymous_id,
        user: JSON.stringify(user),
      },
    };
    createBranchLink(linkData);
  }

  function toAppAAA(button_text: string, page_name: string, aaaId: string) {
    let win: any = window;
    var anonymous_id = undefined;
    if (win.analytics != undefined && win.analytics.user()) {
      anonymous_id = win.analytics.user().anonymousId();
      win.analytics.track("Button Clicked", {
        page_name: page_name,
        button_text: button_text,
      });
    }

    var linkData = {
      feature: "AAAny Portal Go to ask",
      channel: "web",
      data: {
        anonymousId: anonymous_id,
        aaa_id: aaaId,
      },
    };
    createBranchLink(linkData);
  }

  function toAppProfile(button_text: string, page_name: string) {
    let win: any = window;
    var anonymous_id = undefined;
    if (win.analytics != undefined && win.analytics.user()) {
      anonymous_id = win.analytics.user().anonymousId();
      win.analytics.track("Button Clicked", {
        page_name: page_name,
        button_text: button_text,
      });
    }

    var linkData = {
      feature: "AAAny Portal Like",
      channel: "web",
      data: {
        anonymousId: anonymous_id,
        user: JSON.stringify(user),
      },
    };
    createBranchLink(linkData);
  }

  function isAppleMobileDevice() {
    var userAgent = navigator.userAgent;
    var appleKeywords = ["iPhone", "iPad"];
    for (var i = 0; i < appleKeywords.length; i++) {
      if (userAgent.indexOf(appleKeywords[i]) !== -1) {
        return true;
      }
    }
  }

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute("content", "#000000");
    }
  }, []);

  useEffect(() => {
    if (user) {
      // const appleTag = document.querySelector('meta[name="apple-itunes-app"]');
      // if (appleTag) {
      //   appleTag.setAttribute(
      //     "content",
      //     `app-id=6450619356, app-argument=aaanyTest://?userId=${user._id}`
      //   );
      // }
    }

    if (
      user &&
      socialLinks &&
      socialLinks?.length > 0 &&
      socialContentRef.current &&
      bioContentRef.current &&
      sizerRef.current
    ) {
      var rect = bioContentRef.current.getBoundingClientRect();
      var bottomDistance = rect.top + rect.height;
      let el = socialContentRef.current;
      el.style.top = `${bottomDistance + 14}px`;
    }

    if (nexPageBtnRef.current && socialContentRef.current) {
      var rect = socialContentRef.current.getBoundingClientRect();
      nexPageBtnRef.current.style.left = `${
        socialContentRef.current.offsetLeft +
        socialContentRef.current.offsetWidth +
        17
      }px`;
      nexPageBtnRef.current.style.top = `${
        socialContentRef.current.offsetTop + 1
      }px`;
    }

    if (prePageBtnRef.current && socialContentRef.current) {
      var rect = socialContentRef.current.getBoundingClientRect();

      prePageBtnRef.current.style.left = `${
        socialContentRef.current.offsetLeft - 17 - 16
      }px`;
      prePageBtnRef.current.style.top = `${
        socialContentRef.current.offsetTop + 1
      }px`;
    }
  }, [
    socialContentRef,
    bioContentRef,
    socialLinks,
    sizerRef,
    user,
    nexPageBtnRef,
    prePageBtnRef,
  ]);

  useEffect(() => {
    if (username) {
      getHightlights(username).then((res) => {
        setHightlights(res);
      });

      getUserInfo(username).then((res) => {
        console.log(res);
        setUser(res);
      });

      getSociallinks(username).then((res) => {
        setSocialLinks(res);
        console.log(res);
      });
    }

    if (shuffleContainerRef.current) {
      setItemW((shuffleContainerRef.current.clientWidth - 16) / 2);
    }
  }, [username]);
  let shuffleInstance: Shuffle | null = null;

  useEffect(() => {
    if (
      shuffleInstance == null &&
      shuffleContainerRef.current &&
      user != null &&
      hightlights &&
      hightlights.length > 0 &&
      sizerRef &&
      socialLinks
    ) {
      // 初始化Shuffle.js实例
      shuffleInstance = new Shuffle(shuffleContainerRef.current, {
        itemSelector: ".grid-item",
        sizer: ".my-sizer-element",
      });
      if (hightlights != null && hightlights.length > 0) {
        shuffleInstance.update();
      }
    }
    return () => shuffleInstance?.destroy();
  }, [itemW, shuffleContainerRef, hightlights, user, sizerRef, socialLinks]);

  return (
    <div className="absolute inset-0  flex  pl-0 lg:pl-[0%] xl:pl-[0%] overflow-hidden overscroll-none justify-center bg-black md:bg-white">
      <div
        style={{
          backgroundImage: `url(${bg})`,
        }}
        className="max-w-md w-full h-screen overflow-y-scroll px-[6px] overflow-x-hidden relative pb-[240px] bg-no-repeat bg-cover"
      >
        <div className="fixed flex justify-center  w-full h-full left-0 top-0 z-1">
          <div className="w-full max-w-md h-full bg-gradient-to-b to-black from-transparent via-transparent"></div>
        </div>
        <div className="max-w-md flex flex-col items-center z-20">
          {/* Avatar */}
          {user && (
            <div className="mt-[63px] w-[80px] h-[80px] z-20 flex-shrink-0 rounded-full bg-gray-400 overflow-hidden">
              {!user.avatar && <img src={avatar} alt="" />}
              {user?.avatar && <img src={user?.avatar} alt="" />}
            </div>
          )}

          {/* Nickname */}
          {user && (
            <div className="font-semibold text-2xl tracking-[-0.408px] mt-2 text-white z-20 w-full line-clamp-1 text-center flex-shrink-0">
              {user?.name}
            </div>
          )}
          {/* Username */}
          <div className="font-medium text-sm leading-[22px] tracking-[-0.41px] mt-1 text-[rgb(153,153,153)] z-20 text-center w-full line-clamp-1">
            {user?.username && `@${user?.username}`}
          </div>
          {/* Bio */}
          {user && (
            <div
              ref={bioContentRef}
              className="font-normal text-xs leading-[22px] tracking-[-0.408px] mt-[4px] text-center text-white z-20 whitespace-pre-wrap"
            >
              {user!.bio && user.bio.length > 0
                ? user!.bio
                : "I'm a little teapot didn't fill my bio."}
            </div>
          )}
          {/* Social links */}
          {socialLinks && socialLinks?.length > 0 && (
            <div
              onScroll={(r) => {
                if (r.currentTarget.scrollLeft == 0) {
                  nexPageBtnRef.current!.style.visibility = "visible";
                } else {
                  nexPageBtnRef.current!.style.visibility = "hidden";
                }
                if (r.currentTarget.scrollLeft != 0) {
                  prePageBtnRef.current!.style.visibility = "visible";
                } else {
                  prePageBtnRef.current!.style.visibility = "hidden";
                }
                // setScrollLeft(r.currentTarget.scrollLeft);
              }}
              ref={socialContentRef}
              className={classNames(
                "absolute mt-[8px] w-full h-[56px] space-x-[12px] max-w-[296px] flex flex-no-wrap  items-start text-white  z-100 overflow-x-scroll md:overflow-x-hidden",
                socialLinks.length > 7
                  ? "justify-start pr-[132px]"
                  : "justify-center"
              )}
            >
              {socialLinks?.map((s) => {
                return (
                  <SocialItem user={user} key={`${s._id}`} sociallink={s} />
                );
              })}
            </div>
          )}
          {socialLinks && socialLinks?.length > 7 && (
            <button
              onClick={() => {
                socialContentRef.current!.scrollTo({ left: 2000 });
              }}
              ref={nexPageBtnRef}
              className={classNames("absolute")}
            >
              <img src={next_page} alt="" />
            </button>
          )}
          {socialLinks && socialLinks?.length > 7 && (
            <button
              onClick={() => {
                socialContentRef.current!.scrollTo({ left: 0 });
              }}
              ref={prePageBtnRef}
              className={classNames("absolute invisible")}
            >
              <img src={pre_page} alt="" />
            </button>
          )}

          {/* Following */}
          {/* <div className="flex items-center space-x-[14px] mt-[8px] z-20">
            {user && <Following user={user} />}
            {user && <Followers user={user} />}
          </div> */}
          <div
            ref={shuffleContainerRef}
            className={classNames(
              "grid grid-cols-2 w-full",
              socialLinks && socialLinks.length > 0 ? "mt-20" : "mt-8"
            )}
          >
            {hightlights?.map((h) => {
              return (
                <HighlightCardContainer
                  user={user}
                  isDesktop={false}
                  key={`${h._id}`}
                  hightlight={h}
                  itemW={itemW}
                  onGotoAsk={(aaaId) => {
                    toAppAAA("AAAny Highlight Detail", "Go to ask", aaaId);
                  }}
                  onLike={() => {
                    toAppProfile("AAAny Highlight Detail", "Like");
                  }}
                />
              );
            })}
            <div ref={sizerRef} className="my-sizer-element"></div>
          </div>
          {user && (
            <button
              ref={createRef}
              id="create_portal"
              onClick={() => {
                toApp("AAAny Portal", "Create your AAAny Portal");
              }}
              className={classNames(
                "text-[#FF53B1] mt-[74px] font-medium border-[#FF53B1] rounded-full w-[229px] h-[45px] border flex text-[14px] justify-center items-center space-x-[6px] z-30",
                segmentReady && user ? "visible" : "invisible"
              )}
            >
              <img className="w-[27px]" src={create_aaany} alt="" />
              <span>Create your AAAny Portal</span>
            </button>
          )}
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            backgroundColor: "#F2F2F2",
            padding: "6px",
            color: "black",
          },
        }}
      />
    </div>
  );
}
