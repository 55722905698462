import { Highlight } from "../../api/AmaApi";
import link_right from "../../assets/hightlight/link_right.svg";

export function hostName(link: string) {
  var originalLink = link;
  var url = new URL(originalLink);
  var domain = url.hostname;
  domain = domain.replace(/^www\./, "");
  return domain;
}

export default function HighlightLink({
  hightlight,
}: {
  hightlight: Highlight;
}) {
  return (
    <div className="flex h-[16px] px-1 py-[2px] bg-[rgba(255,255,255,0.9)] rounded-full border max-w-[130px] w-fit items-center">
      <div className="w-[12px] h-[12px] flex-shrink-0">
        <img src={link_right} alt="" />
      </div>
      <div className="font-normal text-[8px] line-clamp-1 text-ellipsis">
        {hostName(hightlight.link)}
      </div>
    </div>
  );
}
