import axios, { Axios, AxiosError } from "axios";
import { User } from "../models/user.model";

const baseURLs: Record<string, string> = {
  "test.aaany.xyz": "https://api.aaany.xyz",
  "aaany.app": "https://api.aaany.app",
  "dev.aaany.xyz": "https://api-dev.aaany.xyz",
  "temp.aaany.xyz": "https://api-temp.aaany.xyz",
  "localhost:3000": "https://api.aaany.xyz",
  "192.168.0.100:3000": "https://api.aaany.xyz",
};

let baseURL = baseURLs[window.location.host] || "https://api.aaany.app";
console.log("baseurl", baseURL);
// location.host === "test.aaany.xyz"
//   ? "https://api.aaany.xyz"
//   : "https://api.aaany.app";

// let baseURL = "https://api-dev.aaany.xyz";
const Api = axios.create({
  baseURL,
  timeout: 600000,
});

export type CommonResError = {
  data: {
    statusCode: number;
    message: string;
    path: string;
  };
};

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const r: CommonResError = {
      data: { statusCode: 500, message: "unkonw", path: "" },
    };
    if (error.response) {
      let data = (error.response as CommonResError).data;
      r.data = data;
      return Promise.reject(r);
    } else {
      return Promise.reject(r);
    }
  }
);

Api.interceptors.request.use(
  (config) => {
    let token = "";
    return {
      ...config,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `${token ? "Bearer " + token : ""}`,
      },
    };
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default Api;
