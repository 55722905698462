import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function AmaContentLoading() {
  return (
    <SkeletonTheme
      baseColor="rgb(228,228,228)"
      highlightColor="rgb(233,233,233)"
    >
      <div className="relative w-full h-full overflow-x-hidden flex flex-col px-4 py-4 space-y-1.5 flex-shrink-0">
        {/* 标题 */}
        <Skeleton width={240} height={24} />
        {/* 点赞等 */}
        <Skeleton width={84} height={18} />
        <Skeleton width={66} height={18} />
        <div className="flex space-x-4">
          <div className="flex flex-col items-center">
            <Skeleton width={36} height={36} circle />
            <Skeleton width={40} height={13} />
          </div>
          <div className="flex flex-col items-center">
            <Skeleton width={36} height={36} circle />
            <Skeleton width={40} height={13} />
          </div>
          <div className="flex flex-col items-center">
            <Skeleton width={36} height={36} circle />
            <Skeleton width={40} height={13} />
          </div>
        </div>
        {/* 作者 */}
        <div className="flex space-x-4">
          <Skeleton width={210} height={88} />
        </div>
        <Skeleton width={320} height={200} />
      </div>
    </SkeletonTheme>
  );
}
