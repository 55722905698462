import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function AmaMessageLoading() {
  return (
    <SkeletonTheme
      baseColor="rgb(228,228,228)"
      highlightColor="rgb(233,233,233)"
    >
      <div className="relative w-full h-fit overflow-hidden flex flex-col p-2 space-y-1.5 flex-shrink-0">
        {/* 评论1 */}
        <div className="flex space-x-2">
          <Skeleton width={42} height={42} circle />
          <div>
            <div className="flex space-x-2">
              <Skeleton width={40} height={13} />
              <Skeleton width={58} height={13} />
            </div>
            <Skeleton width={88} height={13} />
          </div>
        </div>
        {/* 评2 */}
        <div className="flex space-x-2">
          <Skeleton width={42} height={42} circle />
          <div>
            <div className="flex space-x-2">
              <Skeleton width={40} height={13} />
              <Skeleton width={58} height={13} />
              <Skeleton width={58} height={13} />
            </div>
            <Skeleton width={280} height={13} />
          </div>
        </div>

        <div className="flex space-x-2">
          <Skeleton width={42} height={42} circle />
          <div>
            <div className="flex space-x-2">
              <Skeleton width={68} height={13} />
              <Skeleton width={58} height={13} />
            </div>
            <Skeleton width={120} height={13} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
