import classNames from "classnames";
import Shuffle from "shufflejs";
import { useEffect, useRef, useState } from "react";
import avatar from "../assets/avatar.png";
import {
  Highlight,
  SocialLink,
  getHightlights,
  getSociallinks,
  getUserInfo,
} from "../api/AmaApi";
import { useLocation, useParams } from "react-router-dom";
import { User } from "../models/user.model";
import { Toaster } from "react-hot-toast";
import HighlightCardContainer from "../components/Highlight/HighlightCardContainer";
import SocialItem from "../components/Highlight/SocialItem";
import create_aaany from "../assets/hightlight/create_aaany.png";
import desktop_bg from "../assets/hightlight/desktop_bg.png";
import useWindowSize from "../hooks/useWindowSize";
import branch from "branch-sdk";

export default function PortalDesktop({
  segmentReady,
}: {
  segmentReady: boolean;
}) {
  let { username } = useParams();
  username = username || "aaany";

  // page_name: "AAAny Portal","Create your AAAny Portal"
  //       button_text: "Create your AAAny Portal",
  function toApp(button_text: string, page_name: string) {
    let win: any = window;
    var anonymous_id = undefined;
    if (win.analytics != undefined && win.analytics.user()) {
      anonymous_id = win.analytics.user().anonymousId();
      win.analytics.track("Button Clicked", {
        page_name: page_name,
        button_text: button_text,
      });
    }

    var linkData = {
      feature: "AAAny Portal",
      channel: "web",
      data: {
        anonymousId: anonymous_id,
        user: JSON.stringify(user),
      },
    };
    createBranchLink(linkData);
  }

  function toAppAAA(button_text: string, page_name: string, aaaId: string) {
    let win: any = window;
    var anonymous_id = undefined;
    if (win.analytics != undefined && win.analytics.user()) {
      anonymous_id = win.analytics.user().anonymousId();
      win.analytics.track("Button Clicked", {
        page_name: page_name,
        button_text: button_text,
      });
    }

    var linkData = {
      feature: "AAAny Portal Go to ask",
      channel: "web",
      data: {
        anonymousId: anonymous_id,
        aaa_id: aaaId,
      },
    };
    createBranchLink(linkData);
  }

  function toAppProfile(button_text: string, page_name: string) {
    let win: any = window;
    var anonymous_id = undefined;
    if (win.analytics != undefined && win.analytics.user()) {
      anonymous_id = win.analytics.user().anonymousId();
      win.analytics.track("Button Clicked", {
        page_name: page_name,
        button_text: button_text,
      });
    }

    var linkData = {
      feature: "AAAny Portal Like",
      channel: "web",
      data: {
        anonymousId: anonymous_id,
        user: JSON.stringify(user),
      },
    };
    createBranchLink(linkData);
  }

  const margin = 30;
  const itemW = 265;

  const col6W = 5 * margin + 6 * itemW;
  const col5W = 4 * margin + 5 * itemW;
  const col4W = 3 * margin + 4 * itemW;
  const col3W = 2 * margin + 3 * itemW;
  const col2W = 1 * margin + 2 * itemW;

  const highlightsContainerRef = useRef<HTMLDivElement>(null);
  const shuffleContainerRef = useRef<HTMLDivElement>(null);

  const socialContentRef = useRef<HTMLDivElement>(null);
  const bioContentRef = useRef<HTMLDivElement>(null);
  const createRef = useRef<HTMLButtonElement>(null);

  const [maxW, setMaxW] = useState("max-w-full");

  const size = useWindowSize();

  useEffect(() => {
    if (highlightsContainerRef.current) {
      let w = highlightsContainerRef.current.offsetWidth;
      if (w > col6W) {
        setMaxW("max-w-[1740px]");
      } else if (w > col5W) {
        setMaxW("max-w-[1445px]");
      } else if (w > col4W) {
        setMaxW("max-w-[1150px]");
      } else if (w > col3W) {
        setMaxW("max-w-[855px]");
      } else {
        setMaxW("max-w-[560px]");
      }
    }
  }, [size, highlightsContainerRef]);

  useEffect(() => {
    console.log("maxW = ", maxW);
  }, [maxW]);

  const [user, setUser] = useState<User>();

  const [hightlights, setHightlights] = useState<[Highlight] | null>(null);

  const [socialLinks, setSocialLinks] = useState<[SocialLink] | null>(null);

  const uLocation = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(uLocation.search);
    const preview = searchParams.get("preview") as unknown as boolean;
    if (preview && createRef.current && user) {
      createRef.current.style.visibility = "hidden";
    }
  }, [uLocation, createRef, user]);

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute("content", "#000000");
    }
  }, []);

  useEffect(() => {
    if (username) {
      getHightlights(username).then((res) => {
        setHightlights(res);
      });

      getUserInfo(username).then((res) => {
        console.log(res);
        setUser(res);
      });

      getSociallinks(username).then((res) => {
        setSocialLinks(res);
        console.log(res);
      });
    }

    if (shuffleContainerRef.current) {
    }
  }, [username]);

  useEffect(() => {
    if (shuffleContainerRef && shuffleContainerRef.current) {
      // 初始化Shuffle.js实例
      const shuffleInstance = new Shuffle(shuffleContainerRef.current!, {
        itemSelector: ".grid-item",
        columnWidth: itemW,
        columnThreshold: itemW,
        speed: 0.5,
        gutterWidth: 30,
      });
      shuffleInstance.update();
    }
    return () => {};
  }, [shuffleContainerRef, hightlights, user, maxW]);

  return (
    <div className="flex bg-[rgb(12,12,12)] w-full h-screen static overflow-y-scroll p-0 m-0 overflow-x-hidden">
      <div className="absolute left-0 top-0 w-[384px] flex-shrink-0 bg-gradient-to-r from-[rgba(12,12,12,1)] via-[rgba(20,20,20,1)] to-[rgba(20,20,20,0.8)] border-r border-[#666666] px-[44px] flex flex-col items-center h-full">
        {user && (
          <div className="mt-[75px] w-[120px] h-[120px] z-20 flex-shrink-0 rounded-full bg-gray-400 overflow-hidden">
            {!user?.avatar && <img src={avatar} alt="" />}
            {user?.avatar && <img src={user?.avatar} alt="" />}
          </div>
        )}

        {user && (
          <div className="font-semibold text-2xl tracking-[-0.408px] mt-2 text-white z-20 w-full line-clamp-1 text-center flex-shrink-0">
            {user?.name}
          </div>
        )}

        <div className="font-medium text-sm leading-[22px] tracking-[-0.41px] mt-1 text-[rgb(153,153,153)] z-20 text-center w-full line-clamp-1">
          {user?.username && `@${user?.username}`}
        </div>

        {user && (
          <div
            ref={bioContentRef}
            className="font-normal text-xs leading-[22px] tracking-[-0.408px] mt-[4px] text-center text-white z-20 whitespace-pre-wrap"
          >
            {user!.bio && user.bio.length > 0
              ? user!.bio
              : "I'm a little teapot didn't fill my bio."}
          </div>
        )}
        {socialLinks && socialLinks?.length > 0 && (
          <div
            ref={socialContentRef}
            className={classNames(
              "mt-[38px] w-full flex justify-center flex-wrap items-start text-white z-100  gap-x-3 gap-y-3"
            )}
          >
            {socialLinks?.map((s) => {
              return <SocialItem user={user} key={`${s._id}`} sociallink={s} />;
            })}
          </div>
        )}
        {user && (
          <div className="absolute bottom-[68px] left-0 flex justify-center w-full">
            <button
              ref={createRef}
              id="create_portal"
              onClick={() => {
                toApp("AAAny Portal", "Create your AAAny Portal");
              }}
              className={classNames(
                "text-[#FF53B1] mt-[74px] font-medium border-[#FF53B1] rounded-full w-[254px] h-[45px] border flex text-[14px] justify-center items-center space-x-[6px] z-30 justify-self-end self-end",
                segmentReady && user ? "visible" : "invisible"
              )}
            >
              <img className="w-[27px]" src={create_aaany} alt="" />
              <span>Create your AAAny Portal</span>
            </button>
          </div>
        )}
      </div>

      <div
        ref={highlightsContainerRef}
        style={{
          backgroundImage: `url(${desktop_bg})`,
        }}
        className={classNames(
          "flex relative justify-center bg-contain ml-[384px] w-full h-full px-[30px]"
        )}
      >
        <div className="fixed flex justify-center  w-full h-full left-[384px] top-0 z-1">
          <div className="w-full  h-full bg-gradient-to-b to-black from-transparent"></div>
        </div>
        <div
          ref={shuffleContainerRef}
          className={classNames("flex-shrink-0 w-full mt-[75px]", maxW)}
        >
          {hightlights?.map((h) => {
            return (
              <HighlightCardContainer
                user={user}
                isDesktop={true}
                key={`${h._id}`}
                hightlight={h}
                itemW={itemW}
                onGotoAsk={(aaaId) => {
                  toAppAAA("AAAny Highlight Detail", "Go to ask", aaaId);
                }}
                onLike={() => {
                  toAppProfile("AAAny Highlight Detail", "Like");
                }}
              />
            );
          })}
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            backgroundColor: "#F2F2F2",
            padding: "6px",
            color: "black",
          },
        }}
      />
    </div>
  );
}

export function createBranchLink(linkData: any) {
  let win: any = window;
  branch.link(linkData, function (err: any, link: string) {
    if (err == null) {
      window.location.href = link;
    } else {
      win.analytics.track("System Log", {
        error: err,
      });
      if (isAppleMobileDevice()) {
        window.location.href = "aaany://";
      } else {
        window.location.href =
          "https://apps.apple.com/app/aaany-ask-anyone-anything/id6450619356";
      }
    }
  });
}

function isAppleMobileDevice() {
  var userAgent = navigator.userAgent;
  var appleKeywords = ["iPhone", "iPad"];
  for (var i = 0; i < appleKeywords.length; i++) {
    if (userAgent.indexOf(appleKeywords[i]) !== -1) {
      return true;
    }
  }

  return false;
}
