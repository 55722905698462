export function showAddress(address: string): string {
  if (!address || address.length != 42) return address;
  const match = /^(.{5,5})(.*)(.{4,4})$/gi.exec(address);
  return `${match![1]}...${match![3]}`;
}

export function limitStr(str: string, limit: number) {
  if (str) {
    if (/.*[\u4e00-\u9fa5]+.*$/.test(str)) {
      // 中文
      return str.length > limit ? `${str.substring(0, limit)}...` : str;
    } else {
      // 英文
      if (str.length > limit) {
        var trimmedString = str.substr(0, limit);

        //re-trim if we are in the middle of a word
        trimmedString = trimmedString.substr(
          0,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
        );
        return trimmedString + "...";
      } else {
        return str;
      }
    }
  } else {
    return "";
  }
}

export function limitStrNomal(str: string, limit: number) {
  if (str) {
    return str.length > limit ? `${str.substring(0, limit)}...` : str;
  } else {
    return "";
  }
}
