import classNames from "classnames";
import { Highlight } from "../../api/AmaApi";
import HighlightLink from "./HighlightLink";
import link_bg from "../../assets/hightlight/link_bg.svg";
import PlaceHolderImage from "../AAA/PlaceHolderImage";

export default function LinkCard({ hightlight }: { hightlight: Highlight }) {
  function getLineClamp() {
    if (hightlight.image && hightlight.image.length > 0) {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return "line-clamp-2";
        case "4:3":
          return "line-clamp-2";
        case "3:4":
          return "line-clamp-4";
        default:
          return "line-clamp-2";
      }
    } else {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return "line-clamp-6";
        case "4:3":
          return "line-clamp-3";
        case "3:4":
          return "line-clamp-12";
        default:
          return "line-clamp-2";
      }
    }
  }

  function getTitleLineClamp() {
    switch (hightlight.aspect_ratio) {
      case "4:3":
        return "line-clamp-1";
      default:
        return "line-clamp-2";
    }
  }

  return hightlight.image ? (
    <div className="flex flex-col h-full relative">
      <div className="grow rounded-[14px] overflow-hidden relative">
        <PlaceHolderImage type="message" src={hightlight.image} />
        {/* <img

          className="object-cover w-full h-full border-none"
          src={`${hightlight.image}`}
        /> */}
        <div className="absolute left-[9px] bottom-[9px]">
          <HighlightLink hightlight={hightlight} />
        </div>
      </div>
      <div className="absolute w-full h-full z-[-2] bg-[#FDFDFD] top-[0px]"></div>
      <div
        className={classNames(
          "flex-none px-2 pt-[4px]",
          hightlight.description ? "pb-4" : "pb-2"
        )}
      >
        <div className="font-bold leading-[120%] h-[36px] flex flex-col justify-center">
          <p
            className={classNames(
              "font-bold text-[11px] leading-[120%]",
              getTitleLineClamp()
            )}
          >
            {hightlight.title}
          </p>
        </div>
        <div
          className={classNames(
            "font-medium text-[10px] leading-[12px] text-[#666]",
            getLineClamp()
          )}
        >
          {hightlight.description}
        </div>
      </div>
    </div>
  ) : (
    <div className="relative px-2 pb-4 flex h-full flex-col bg-[#FDFDFD] justify-between">
      <img className="absolute left-0 top-0 z-0" src={link_bg} alt="" />
      <div className="mt-[9px] z-10">
        <HighlightLink hightlight={hightlight} />
      </div>
      <div
        className={classNames(
          "flex grow flex-col mt-[6px] z-10",
          hightlight.description ? "justify-between" : "justify-end"
        )}
      >
        <div
          className={classNames(
            "font-bold text-sm leading-[120%]",
            getTitleLineClamp()
          )}
        >
          {hightlight.title}
        </div>
        <div
          className={classNames(
            "text-[10px] font-medium text-[#666666] leading-[120%]",
            getLineClamp()
          )}
        >
          {hightlight.description}
        </div>
      </div>
    </div>
  );
}
