import { ReactNode } from "react";
import AmaContentLoading from "./AmaContentLoading";
import "react-loading-skeleton/dist/skeleton.css";
import AmaMessageLoading from "./AmaMessageLoading";
import AmaListLoading from "./AmaList.loading";

export type Props = {
  contentType: string;
  children: ReactNode;
  loading: boolean;
  length?: number;
  error?: boolean;
  errorComponent?: ReactNode;
};

export default function ContentLoading({
  loading,
  contentType = "Ama",
  length = 1,
  children,
  error = false,
  errorComponent = <div>Error</div>,
}: Props) {
  const contentTypes = (type: string, key: number) => {
    switch (type) {
      case "AmaList":
        return <AmaListLoading key={type + key} />;
      case "Ama":
        return <AmaContentLoading key={type + key} />;
      case "AmaMessages":
        return <AmaMessageLoading key={type + key} />;
      case "AmaAndMessages":
        return (
          <div key={type + key} className="flex flex-col px-2">
            <AmaContentLoading />
            <AmaMessageLoading />
          </div>
        );
      default:
        return null;
    }
  };

  const arrayOfSkeleton = () => {
    const skeletons = [];
    for (let i = 0; i < length; i++) {
      skeletons.push(contentTypes(contentType, i));
    }
    return skeletons;
  };

  const showContent = () => {
    if (error) {
      return errorComponent;
    } else if (loading) {
      return arrayOfSkeleton();
    } else {
      return children;
    }
  };

  return <div className="w-full  h-full">{showContent()}</div>;
}
