import { Ama } from "../../models/ama.model";
import { Avatar } from "../../routers/root.layout";
import { limitStrNomal } from "../../util/string";
import link_bg from "../../assets/hightlight/link_bg.svg";
import classNames from "classnames";

import puzzleMark from "../../assets/hightlight/puzzle“.svg";
import blastMark from "../../assets/hightlight/blast.svg";
import aquaMark from "../../assets/hightlight/aqua.svg";
import feverMark from "../../assets/hightlight/fever.svg";

import aqua01 from "../../assets/hightlight/aqua01.svg";
import aqua02 from "../../assets/hightlight/aqua02.svg";

import puzzle01 from "../../assets/hightlight/puzzle01.svg";
import puzzle02 from "../../assets/hightlight/puzzle02.svg";

import fever01 from "../../assets/hightlight/ferer01.svg";

import shave from "shave";
import { Highlight } from "../../api/AmaApi";
import { useEffect, useRef } from "react";
import { Message } from "../../models/message.model";
import { useNavigate } from "react-router-dom";
import { User } from "../../models/user.model";
import useWindowSize from "../../hooks/useWindowSize";

export default function AAARefers({ aaa }: { aaa?: Ama }) {
  return aaa && aaa.refers instanceof Array && aaa.refers.length > 0 ? (
    <div
      onClick={() => {}}
      className="w-full h-fit flex flex-col space-y-[6px] mt-[6px]"
    >
      {aaa.refers.map((refer) => {
        switch (refer.type) {
          case "aaa":
            let referAAA = refer.aaa!;
            return <ReferAAACard aaa={referAAA} />;
            break;
          case "highlight":
            let hightlight = refer.highlight!;
            if (hightlight.type == "Link") {
              return (
                <ReferLinkCard hightlight={hightlight} author={aaa.owner} />
              );
            } else if (hightlight.type == "Image") {
              return (
                <ReferImageCard hightlight={hightlight} author={aaa.owner} />
              );
            } else if (hightlight.type == "Text") {
              return (
                <ReferTextCard hightlight={hightlight} author={aaa.owner} />
              );
            } else if (hightlight.type == "Quote") {
              return (
                <ReferQuoteCard hightlight={hightlight} author={aaa.owner} />
              );
            }
            break;
          default:
            return <div></div>;
        }
      })}
    </div>
  ) : (
    <></>
  );
}

export function MessageRefers({ message }: { message?: Message }) {
  const { width, height } = useWindowSize();

  return message &&
    message.refers instanceof Array &&
    message.refers.length > 0 ? (
    <div
      className={classNames(
        "w-full h-fit  space-y-[6px] mt-[6px] px-[16px] mb-[12px]",
        width < 1186 ? "flex flex-col" : "columns-2 gap-[4px]"
      )}
    >
      {message.refers.map((refer) => {
        switch (refer.type) {
          case "aaa":
            let aaa = refer.aaa!;
            return <ReferAAACard aaa={aaa} />;
            break;
          case "highlight":
            let hightlight = refer.highlight!;
            if (hightlight.type == "Link") {
              return (
                <ReferLinkCard
                  hightlight={hightlight}
                  author={message.author}
                />
              );
            } else if (hightlight.type == "Image") {
              return (
                <ReferImageCard
                  hightlight={hightlight}
                  author={message.author}
                />
              );
            } else if (hightlight.type == "Text") {
              return (
                <ReferTextCard
                  hightlight={hightlight}
                  author={message.author}
                />
              );
            } else if (hightlight.type == "Quote") {
              return (
                <ReferQuoteCard
                  hightlight={hightlight}
                  author={message.author}
                />
              );
            }
            break;
          default:
            return <div></div>;
        }
      })}
    </div>
  ) : (
    <></>
  );
}

function ReferTextCard({
  hightlight,
  author,
}: {
  hightlight: Highlight;
  author: User;
}) {
  const textRef = useRef<HTMLDivElement | null>(null);
  const textContentRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  function navigateToProfile() {
    navigate(`/${author._id}`);
  }

  useEffect(() => {
    if (textRef.current && textContentRef.current) {
      shave(textRef.current, textContentRef.current.offsetHeight - 24 - 9 - 10);
    }
  }, [textRef, hightlight, textContentRef]);

  return (
    <div
      onClick={() => {
        navigateToProfile();
      }}
      ref={textContentRef}
      className={classNames(
        "w-full relative h-[90px] rounded-[16px] overflow-hidden flex flex-col justify-center px-3  pb-[9px] cursor-pointer",
        hightlight.style == "Spring" && "bg-[#B7DEA2]",
        hightlight.style == "Butter" && "bg-[#FFEA7F]",
        hightlight.style == "Mist" && "bg-[#B7B5AD]",
        hightlight.style == "Twilight" && "bg-[#DDDBF8]"
      )}
    >
      <div
        className={classNames(
          "absolute top-0 left-0 h-[13px] w-full",
          hightlight.style == "Spring" && "bg-[#79C697]",
          hightlight.style == "Butter" && "bg-[#F4D050]",
          hightlight.style == "Mist" && "bg-[#B7B5AD]",
          hightlight.style == "Twilight" && "bg-[#BAB6F7]"
        )}
      ></div>
      <div
        ref={textRef}
        className={classNames(
          "mt-3 max-h-fit overflow-hidden w-full  text-xs font-[500] leading-normal whitespace-pre-wrap text-center"
        )}
      >
        {hightlight.text}
      </div>
    </div>
  );
}

function ReferQuoteCard({
  hightlight,
  author,
}: {
  hightlight: Highlight;
  author: User;
}) {
  const textRef = useRef<HTMLDivElement | null>(null);
  const textContentRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  function navigateToProfile() {
    navigate(`/${author._id}`);
  }

  useEffect(() => {
    if (textRef.current && textContentRef.current) {
      shave(textRef.current, textContentRef.current.offsetHeight - 24 - 9 - 10);
    }
  }, [textRef, hightlight, textContentRef]);

  function getMarkImage() {
    if (hightlight.style == "Puzzle") {
      return puzzleMark;
    } else if (hightlight.style == "Blast") {
      return blastMark;
    } else if (hightlight.style == "Aqua") {
      return aquaMark;
    } else if (hightlight.style == "Fever") {
      return feverMark;
    } else {
      return puzzleMark;
    }
  }

  function getOtherImage() {
    if (hightlight.style == "Puzzle") {
      return (
        <>
          <img src={puzzle01} className="absolute left-0 bottom-0" />
          <img src={puzzle02} className="absolute right-0 top-0" />
        </>
      );
    } else if (hightlight.style == "Blast") {
      return <></>;
    } else if (hightlight.style == "Aqua") {
      return (
        <>
          <img src={aqua01} className="absolute left-0 bottom-0" />
          <img src={aqua02} className="absolute right-0 top-0" />
        </>
      );
    } else if (hightlight.style == "Fever") {
      return (
        <>
          <img src={fever01} className="absolute left-0 bottom-0" />
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div
      onClick={() => {
        navigateToProfile();
      }}
      ref={textContentRef}
      className={classNames(
        "w-full relative h-[90px] rounded-[16px] overflow-hidden flex flex-col justify-center px-3 cursor-pointer",
        hightlight.style == "Puzzle" && "bg-[#FF9C74]",
        hightlight.style == "Blast" && "bg-[#FFABD2]",
        hightlight.style == "Aqua" && "bg-[#B1E0E3]",
        hightlight.style == "Fever" && "bg-[#D6D997]"
      )}
    >
      <img src={getMarkImage()} className="absolute left-[12px] top-[6px]" />
      {getOtherImage()}
      <div
        style={{
          fontSize: `${
            parseInt(hightlight.quote_text_style?.fontSize ?? "16") - 1
          }px`,
        }}
        className={classNames(
          "font-extrabold w-full px-[42px] tracking-[-0.4px] leading-[110%] text-wrap whitespace-pre-wrap text-center line-clamp-2 text-[16px] z-[20]"
        )}
      >
        {hightlight.quote_text}
      </div>
    </div>
  );
}

function ReferAAACard({ aaa }: { aaa: Ama }) {
  const navigate = useNavigate();

  function navigateToAAA() {
    navigate(`/aaa/${aaa._id}`);
  }

  if (
    aaa.embedded_media &&
    aaa.embedded_media instanceof Array &&
    aaa.embedded_media?.length > 0
  ) {
    return (
      <div
        onClick={() => {
          navigateToAAA();
        }}
        className="bg-[#FDFDFD] rounded-[16px] flex z-[1] border-[#F2F2F2] border cursor-pointer"
      >
        <div className="rounded-[16px] overflow-hidden w-[90px] h-[90px]">
          <img
            src={aaa.embedded_media[0].url}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="px-[16px] py-[12px]">
          <div className="flex items-center space-x-[4px]">
            <div className="w-[16px] h-[16px]">
              <Avatar user={aaa.owner} />
            </div>
            <div className="text-[#FF53B1] text-[12px] font-semibold">
              {limitStrNomal(aaa.owner.name ?? "", 30)}
            </div>
          </div>
          <div className="text-[#666666] text-[12px] mt-[8px] line-clamp-2">
            {aaa.title}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          navigateToAAA();
        }}
        className="bg-[#FDFDFD] rounded-[16px] px-[15px] py-[12px] h-[90px] border-[#F2F2F2] border cursor-pointer"
      >
        <div className="flex items-center space-x-[4px]">
          <div className="w-[16px] h-[16px]">
            <Avatar user={aaa.owner} />
          </div>
          <div className="text-[#FF53B1] text-[12px] font-semibold">
            {limitStrNomal(aaa.owner.name ?? "", 30)}
          </div>
        </div>
        <div className="text-[#666666] text-[12px] mt-[8px] line-clamp-2">
          {aaa.title}
        </div>
      </div>
    );
  }
}

function ReferImageCard({
  hightlight,
  author,
}: {
  hightlight: Highlight;
  author: User;
}) {
  const navigate = useNavigate();

  function navigateToProfile() {
    navigate(`/${author._id}`);
  }
  return (
    <div
      onClick={() => {
        navigateToProfile();
      }}
      style={{ backgroundImage: `url(${hightlight.image})` }}
      className="relative bg-no-repeat bg-cover z-0 h-[90px] w-full rounded-[16px] overflow-hidden flex items-center cursor-pointer"
    >
      <div className="absolute w-full h-full backdrop-blur-[10px] bg-black/50 z-[-10]"></div>
      <div
        style={{ backgroundImage: `url(${hightlight.image})` }}
        className={classNames(
          "bg-no-repeat bg-cover rounded-[16px] overflow-hidden z-20 relative w-[90px] h-[90px] flex-shrink-0"
        )}
      ></div>

      {hightlight.description && (
        <div className="text-[16px] font-bold px-3 text-white leading-[15px] ml-[16px]">
          <span className="line-clamp-2">{hightlight.description}</span>
        </div>
      )}
    </div>
  );
}

function ReferLinkCard({
  hightlight,
  author,
}: {
  hightlight: Highlight;
  author: User;
}) {
  let url = new URL(hightlight.link);
  let host = url.hostname;

  const navigate = useNavigate();

  function navigateToProfile() {
    navigate(`/${author._id}`);
  }

  if (hightlight.image && hightlight.image?.length > 0) {
    return (
      <div
        onClick={() => {
          navigateToProfile();
        }}
        className="bg-[#FDFDFD] bg-no-repeat relative rounded-[16px] h-[90px] flex cursor-pointer"
      >
        <div className="w-[90px] h-[90px]">
          <img
            src={hightlight.image}
            alt=""
            className="rounded-[16px] border-[1px] border-[#FDFDFD] w-full h-full"
          />
        </div>
        <div className="relative px-[16px] py-[12px]">
          <div className="font-bold text-[16px] line-clamp-1">
            {hightlight.title}
          </div>
          <div className="text-[#666666] text-[12px] mt-[4px] line-clamp-2 leading-[14px]">
            {hightlight.description}
          </div>
          <div className="text-[8px] text-[#295381] absolute left-[15px] bottom-[8px]">
            {host}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          navigateToProfile();
        }}
        style={{ backgroundImage: `url(${link_bg})` }}
        className="bg-[#FDFDFD] bg-no-repeat relative rounded-[16px] px-[15px] py-[12px] h-[90px] cursor-pointer"
      >
        <div className="font-bold text-[16px] line-clamp-1">
          {hightlight.title}
        </div>
        <div className="text-[#666666] text-[12px] mt-[4px] line-clamp-2 leading-[14px]">
          {hightlight.description}
        </div>
        <div className="text-[8px] text-[#295381] absolute left-[15px] bottom-[8px]">
          {host}
        </div>
      </div>
    );
  }
}
