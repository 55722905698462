import dayjs from "dayjs";
import { Ama, CreateAmaDto } from "../models/ama.model";
import { Message } from "../models/message.model";
import Api from "./Api";
import { User } from "../models/user.model";

export default class AmaApi {}

export interface IListResult<T, U = number | string> {
  cursor: U;
  count: number;
  list: Array<T>;
}

type MessageListResult = IListResult<Message, number>;

export enum AmaStatusEnum {
  pending = 1, // 未开始
  progress = 2, // 进行中
  done = 4, // 已完成
  cancel = 1024, // 取消
}

export async function getOneAma(id: string) {
  const res = await Api.get<Ama>(`/api/v1/ama/${id}/show`, {
    params: {
      support_media: true,
    },
  });
  return res.data;
}

export async function getMessages(
  id: string,
  limit: number = 20,
  desc: boolean = false,
  cursor: string = ""
) {
  const res = await Api.get<MessageListResult>(
    `/api/v1/ama/${id}/message/all`,
    {
      params: {
        limit: limit,
        time: dayjs().unix() * 1000,
        desc,
        cursor,
        support_media: true,
      },
    }
  );
  return res.data;
}

export async function getOneMessage(amaId: string, messageId: string) {
  const res = await Api.get<Message>(
    `/api/v1/ama/${amaId}/message/${messageId}/card`,
    {
      params: {
        support_media: true,
      },
    }
  );
  return res.data;
}

export async function getOneMessageByShortId(amaId: string, short_id: string) {
  const res = await Api.get<Message>(
    `/api/v1/ama/${amaId}/message/${short_id}/card/short`,
    {
      params: {
        support_media: true,
      },
    }
  );
  return res.data;
}

export interface Highlight {
  _id: string;
  order: number;
  type: string;
  title: string;
  image?: string;
  description: string;
  style: string;
  text_align: string;
  aspect_ratio: string;
  quote_text: string;
  link: string;
  createdAt: number;
  updatedAt: number;
  text: string;
  quote_text_style?: Quote_text_style;
  aaa?: Ama;
  like_count: number;
}

export interface Quote_text_style {
  fontSize?: string;
}

export interface SocialLink {
  _id: string;
  name: string;
  type: string;
  Identifier: string;
  url: string;
}

export async function getHightlights(username: string) {
  const res = await Api.get<[Highlight]>(`/api/v1/highlight`, {
    params: {
      username: username,
    },
  });
  return res.data;
}

export async function getUserInfo(userId: string) {
  const res = await Api.get<User>(`/api/v1/user/${userId}/show`, {});
  return res.data;
}

export async function getSociallinks(username: string) {
  const res = await Api.get<[SocialLink]>(`/api/v1/social_link`, {
    params: {
      username: username,
    },
  });
  return res.data;
}
