import classNames from "classnames";
import { useState } from "react";
import aaa_placeholder from "../../assets/aaa_placeholder.svg";
import message_placeholder from "../../assets/message_placeholder.svg";

export default function PlaceHolderImage({
  src,
  type,
}: {
  src: string;
  type: "aaa" | "message";
}) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div
      className={classNames(
        "w-full h-full relative rounded-[4px] overflow-hidden",
        type == "aaa" ? "bg-[#949E25]" : "bg-[#D9D9D9]"
      )}
    >
      <img
        onLoad={() => {
          setLoaded(true);
        }}
        src={src}
        className={classNames(
          "w-full h-full absolute left-0 top-0 object-cover", //cover
          loaded ? "visible" : "invisible"
        )}
      />
      {!loaded && (
        <img
          src={type == "aaa" ? aaa_placeholder : message_placeholder}
          className="w-full h-full absolute left-0 top-0  object-contain" //object-contain
        />
      )}
    </div>
  );
}
