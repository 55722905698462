import { useEffect, useRef, useState } from "react";

import QuoteAqua11 from "../assets/hightlight/Quote-aqua-1_1.svg";
import QuoteAqua34 from "../assets/hightlight/Quote-aqua-3_4.svg";
import QuoteAqua43 from "../assets/hightlight/Quote-aqua-4_3.svg";

import QuoteBlast11 from "../assets/hightlight/Quote-blast-1_1.svg";
import QuoteBlast34 from "../assets/hightlight/Quote-blast-3_4.svg";
import QuoteBlast43 from "../assets/hightlight/Quote-blast-4_3.svg";

import QuoteFever11 from "../assets/hightlight/Quote-fever-1_1.svg";
import QuoteFever34 from "../assets/hightlight/Quote-fever-3_4.svg";
import QuoteFever43 from "../assets/hightlight/Quote-fever-4_3.svg";

import QuotePuzzle11 from "../assets/hightlight/Quote-puzzle-1_1.svg";
import QuotePuzzle34 from "../assets/hightlight/Quote-puzzle-3_4.svg";
import QuotePuzzle43 from "../assets/hightlight/Quote-puzzle-4_3.svg";
import { Highlight } from "../api/AmaApi";
import shave from "shave";
import classNames from "classnames";
import HighlightLink from "../components/Highlight/HighlightLink";

export default function QuoteCard({
  hightlight,
  isDesktop,
}: {
  hightlight: Highlight;
  isDesktop: boolean;
}) {
  function getBgImage() {
    let style = hightlight.style.toLocaleLowerCase();
    if (hightlight.aspect_ratio == "1:1") {
      if (style == "aqua") {
        return QuoteAqua11;
      } else if (style == "blast") {
        return QuoteBlast11;
      } else if (style == "fever") {
        return QuoteFever11;
      } else if (style == "puzzle") {
        return QuotePuzzle11;
      }
    } else if (hightlight.aspect_ratio == "3:4") {
      if (style == "aqua") {
        return QuoteAqua34;
      } else if (style == "blast") {
        return QuoteBlast34;
      } else if (style == "fever") {
        return QuoteFever34;
      } else if (style == "puzzle") {
        return QuotePuzzle34;
      }
    } else if (hightlight.aspect_ratio == "4:3") {
      if (style == "aqua") {
        return QuoteAqua43;
      } else if (style == "blast") {
        return QuoteBlast43;
      } else if (style == "fever") {
        return QuoteFever43;
      } else if (style == "puzzle") {
        return QuotePuzzle43;
      }
    }
  }

  const haveFontTextContentRef = useRef<HTMLDivElement | null>(null);
  const haveFontTextRef = useRef<HTMLDivElement | null>(null);

  const [layout, setLayout] = useState<string | null>(null);

  useEffect(() => {
    // 1. 创建一个隐藏的 span 获取到 fontSize
    if (haveFontTextContentRef.current?.offsetHeight) {
      if (haveFontTextRef.current?.offsetHeight) {
        const textElement = haveFontTextRef.current!;
        const textContent = haveFontTextContentRef.current!;
        if (textElement.offsetHeight > textContent.offsetHeight) {
          setLayout("justify-start");
          // shave(textElement, textContent.offsetHeight);
        } else {
          setLayout("justify-center");
        }
        setTimeout(() => {
          shave(textElement, textContent.offsetHeight);
        }, 100);
      }
    }
  }, [haveFontTextRef.current, hightlight, haveFontTextContentRef.current]);

  return (
    <div
      style={{ backgroundImage: `url(${getBgImage()})` }}
      className={classNames(
        "w-full h-full flex flex-col px-[9px] bg-no-repeat bg-cover pt-[34px] relative",
        !hightlight.description &&
          !hightlight.title &&
          !hightlight.link &&
          "pb-[20px]"
      )}
    >
      <div
        id={"quote" + hightlight._id}
        style={{
          fontSize: parseInt(
            hightlight.quote_text_style?.fontSize ?? "16" ?? "0"
          ),
        }}
        className=" w-full h-full absolute left-0 top-0 invisible"
      ></div>
      <div
        ref={haveFontTextContentRef}
        className={classNames(
          "grow relative overflow-hidden flex flex-col",
          hightlight.aspect_ratio == "3:4"
            ? "px-[20%]"
            : isDesktop
            ? "px-[6%]"
            : "px-[5%]",
          layout != null && layout,
          isDesktop ? "mt-[10%]" : "mt-[6%]"
        )}
      >
        <div
          ref={haveFontTextRef}
          style={{
            fontSize: `${
              isDesktop
                ? (parseInt(hightlight.quote_text_style?.fontSize ?? "16") -
                    1) *
                  1.49
                : parseInt(hightlight.quote_text_style?.fontSize ?? "16") - 1
            }px`,
          }}
          className={classNames(
            "font-extrabold w-full tracking-[-0.4px] leading-[110%] text-wrap whitespace-pre-wrap",
            hightlight.text_align.toLocaleLowerCase() == "left" && "text-left",
            hightlight.text_align.toLocaleLowerCase() == "right" &&
              "text-right",
            hightlight.text_align.toLocaleLowerCase() == "center" &&
              "text-center"
          )}
        >
          {hightlight.quote_text}
        </div>
      </div>
      <div className="flex-shrink-0 h-max">
        <div
          className={classNames(
            "text-[rgba(0,0,0,0.5)] text-[12px] font-bold leading-[22px] line-clamp-1 px-[5px]",
            !hightlight.description && !hightlight.link && "mb-[10px]"
          )}
        >
          {hightlight.title}
        </div>
        {hightlight.link && (
          <div className="mb-[9px] justify-self-end">
            <HighlightLink hightlight={hightlight} />
          </div>
        )}
      </div>
    </div>
  );
}
