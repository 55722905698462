import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
dayjs.extend(isBetween);
dayjs.extend(duration);

// 将一天前的默认22小时改为24小时
var thresholds = [
  { l: "s", r: 44 },
  { l: "m", r: 89 },
  { l: "mm", r: 44, d: "minute" },
  { l: "h", r: 89 },
  { l: "hh", r: 24, d: "hour" },
  { l: "d", r: 35 },
  { l: "dd", r: 25, d: "day" },
  { l: "M", r: 10 },
  { l: "MM", r: 17, d: "month" },
  { l: "y" },
  { l: "yy", d: "year" },
];
var config = {
  thresholds: thresholds,
};
dayjs.extend(relativeTime, config);

const fromNow = dayjs.prototype.fromNow;
dayjs.prototype.fromNow = function (withoutSuffix: boolean) {
  const diff = this.diff(new Date(), "ms");
  if (Math.abs(diff) / 1000 / 3600 < 24) {
    return fromNow.call(this, withoutSuffix);
  } else {
    if (withoutSuffix) {
      return this.format("HH:MM");
    } else {
      return this.format("MMM DD, YYYY");
    }
  }
};

export default dayjs;

export function timeToAgeStr(date: Dayjs) {
  let currentDate = dayjs();
  let diffInDays = currentDate.diff(date, "day");
  let diffInMonths = currentDate.diff(date, "month");
  let diffInYears = Math.floor(diffInDays / 365);

  if (diffInDays <= 31) {
    return `${diffInDays} ${diffInDays === 1 ? "Day" : "Days"}`;
  } else if (diffInDays <= 365) {
    return `${diffInMonths} ${diffInMonths === 1 ? "Month" : "Months"}`;
  } else {
    diffInMonths = diffInMonths - diffInYears * 12;
    const yearsString = `${diffInYears} ${
      diffInYears === 1 ? "Year" : "Years"
    }`;
    const monthsString =
      diffInMonths > 0
        ? ` ${diffInMonths} ${diffInMonths === 1 ? "Month" : "Months"}`
        : "";
    return `${yearsString} ${monthsString}`;
  }
}
