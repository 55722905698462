import { useEffect, useState } from "react";
import { Message } from "../../models/message.model";
import PlaceHolderImage from "./PlaceHolderImage";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function MessageImageView({
  message,
  c,
}: {
  message: Message;
  c: HTMLDivElement | null;
}) {
  const [imgUrls, setImgUrl] = useState<string[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [full, setFull] = useState<boolean>(false);

  useEffect(() => {
    if (message.embedded_media) {
      setImgUrl(
        message.embedded_media.map((e) => {
          return e.url;
        })
      );
    }
  }, [message.embedded_media]);

  switch (message.embedded_media?.length) {
    case 0:
      return <div></div>;
    // case 1:
    //   let media = message.embedded_media[0]!;
    //   let width = parseFloat(media.width);
    //   let height = parseFloat(media.height);
    //   let maxH = (width / 9) * 16;
    //   let greaterThan9_16 = height > maxH;
    //   var imageH = 0;

    //   var contentW = c?.getBoundingClientRect().width ?? 0;
    //   imageH = ((contentW - 14 * 2 - 16 * 2) / width) * height;

    //   return (
    //     <div className="px-[16px] w-full h-full">
    //       {greaterThan9_16 ? (
    //         <div className="aspect-[9/16] w-full overflow-hidden relative">
    //           <div className="absolute top-0">
    //             <PlaceHolderImage src={media.url} type="message" />
    //           </div>
    //         </div>
    //       ) : (
    //         <div style={{ height: imageH }} className="w-full">
    //           <PlaceHolderImage src={media.url} type="message" />
    //         </div>
    //       )}
    //     </div>
    //   );

    default:
      return (
        <div className="px-[16px] grid grid-cols-3 gap-[4px] overflow-hidden w-full">
          {message.embedded_media!.map((media) => {
            return (
              <div
                onClick={() => {
                  setFull(true);
                }}
                className="aspect-square object-cover"
              >
                <PlaceHolderImage src={media.url} type="message" />
              </div>
            );
          })}
          {imgUrls.length > 0 && full && (
            <Lightbox
              mainSrc={imgUrls[index]!}
              nextSrc={imgUrls[(index + 1) % imgUrls.length]!}
              prevSrc={imgUrls[(index + imgUrls.length - 1) % imgUrls.length]!}
              onCloseRequest={() => {
                setFull(false);
              }}
              onMovePrevRequest={() =>
                setIndex((index + imgUrls.length - 1) % imgUrls.length)
              }
              onMoveNextRequest={() => setIndex((index + 1) % imgUrls.length)}
            />
          )}
        </div>
      );
  }
}
