import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Portal from "./routers/portal";
import branch from "branch-sdk";

const Root = lazy(() => import("./routers/root.layout"));
const ErrorPage = lazy(() => import("./error-page"));

function App() {
  useEffect(() => {
    var branchKey =
      location.host === "aaany.app"
        ? "key_live_oEmp2CEAEqpRo4ptIYTAnjgcxsaRdfzy"
        : "key_live_lElj8yriFeyUb8bXKSgziagfCDivCv4M";
    branch.init(branchKey);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/aaa/:id",
      element: <Root />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/aaa/:id/message/:m",
      element: <Root />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/aaa/:id/m/:sid",
      element: <Root />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/p/:username",
      element: <Portal />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/:username",
      element: <Portal />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/",
      element: <Portal />,
      errorElement: <ErrorPage />,
    },
  ]);
  return (
    <Suspense
      fallback={
        <div className="absolute inset-0 overflow-hidden flex justify-center bg-slate-100">
          <div className="w-full h-full  flex justify-center relative  overflow-hidden"></div>
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

const root = ReactDOM.render(
  <App />,

  document.getElementById("root") as HTMLElement
);

reportWebVitals();
