import toast from "react-hot-toast";

const options = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export default class notification {
  static success(msg) {
    toast.success(msg, options);
  }

  static info(msg, position = "top-center") {
    options.position = position;
    toast.custom(msg, options);
  }

  static error(msg) {
    toast(msg, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  static warning(msg) {
    toast.custom(
      <button
        onClick={() => {}}
        className=" bg-slate-300 h-[38px] rounded-xl flex justify-around items-center px-4  text-xs text-yellow-500 font-bold space-x-2"
      >
        <i className="fa-duotone fa-circle-exclamation"></i>
        {msg}
      </button>
    );
  }

  static loading(msg, ms = 0) {
    const id = toast.loading(msg, { duration: Infinity });
    function close() {
      toast.dismiss(id);
    }
    if (ms > 0) setTimeout(close, ms);
    return close;
  }
}
