import classNames from "classnames";
import { Highlight } from "../../api/AmaApi";
import QuoteCard from "../../routers/QuoteCard";
import LinkCard from "./LinkCard";
import TextCard, { HLDetailTextCard } from "./TextCard";
import image_link from "../../assets/hightlight/image_link.svg";
import linkIcon from "../../assets/hightlight/hl_d_link.svg";
import moreIcon from "../../assets/hightlight/hl_d_more.svg";
import closeIcon from "../../assets/hightlight/close.svg";
import { useEffect, useRef, useState } from "react";
import { User } from "../../models/user.model";
import Popup from "reactjs-popup";
import { render } from "react-dom";
import { MentionView } from "../../routers/root.layout";
import likeIcon from "../../assets/hightlight/like_icon.svg";

export default function HighlightCardContainer({
  hightlight,
  itemW,
  isDesktop = false,
  user,
  onGotoAsk,
  onLike,
}: {
  hightlight: Highlight;
  itemW: number;
  isDesktop: boolean;
  user?: User;
  onGotoAsk: (amaId: string) => void;
  onLike: () => void;
}) {
  function renderCard() {
    switch (hightlight.type) {
      case "Link":
        return <LinkCard hightlight={hightlight} />;
      case "Quote":
        return <QuoteCard isDesktop={isDesktop} hightlight={hightlight} />;
      case "Text":
        return <TextCard hightlight={hightlight} />;
      default:
        return <div>{hightlight.type}</div>;
        break;
    }
  }

  function renderDetailCard() {
    switch (hightlight.type) {
      case "Link":
        return <LinkDetailCard hightlight={hightlight} />;
      case "Quote":
        return (
          <QuoteDetialCard isDesktop={isDesktop} hightlight={hightlight} />
        );
      case "Text":
        return (
          <HLDetailTextCard isDesktop={isDesktop} hightlight={hightlight} />
        );
      case "Image":
        return (
          <ImageDetialCard isDesktop={isDesktop} hightlight={hightlight} />
        );
      default:
        return <div></div>;
        break;
    }
  }

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  return (
    <div
      style={{
        width: itemW,
      }}
      onClick={() => {
        setOpen(true);

        if (hightlight.link) {
          let win: any = window;
          win.analytics.track("Highlight Link Opened", {
            hl_id: hightlight._id,
            type: hightlight.type,
            author: user,
          });
          // userModal.show();
          // window.open(hightlight.link, "_blank");
        }
      }}
      className={classNames(
        isDesktop
          ? "grid-item mb-[30px] overflow-hidden"
          : "grid-item m-[4px]  overflow-hidden",
        hightlight.type.toLocaleLowerCase() == "text"
          ? "rounded-b-[14px]"
          : "rounded-[14px]",
        "cursor-pointer"
      )}
    >
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div
          onClick={() => {
            closeModal();
          }}
          className="w-screen h-screen bg-black/50 flex justify-center items-center"
        >
          <div className="relative">
            {isDesktop && (
              <div className="absolute top-[-25px] right-[-25px] w-[30px] h-[30px]">
                <button className="w-full h-full">
                  <img
                    className="w-full h-full object-fill"
                    src={closeIcon}
                    alt=""
                  />
                </button>
              </div>
            )}
            {hightlight.aaa && (
              <div className="absolute bg-[#D4D4D4] h-[200px] w-[3px] right-[21px] bottom-[168px] z--5 "></div>
            )}
            {hightlight.aaa && (
              <div className="absolute bg-[#D4D4D4] h-[200px] w-[3px] left-[22px] bottom-[168px] z--5 "></div>
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ width: itemW * (isDesktop ? 1.774 : 1.6) }}
              className={classNames(
                "rounded-[14px] overflow-hidden relative",
                hightlight.type != "Image" &&
                  hightlight.type != "Link" &&
                  hightlight.aspect_ratio == "1:1" &&
                  "aspect-[1/1]",
                hightlight.type != "Image" &&
                  hightlight.type != "Link" &&
                  hightlight.aspect_ratio == "4:3" &&
                  "aspect-[4/3]",
                hightlight.type != "Image" &&
                  hightlight.type != "Link" &&
                  hightlight.aspect_ratio == "3:4" &&
                  "aspect-[3/4]",
                hightlight.type == "Link" && "aspect-[2/3]"
              )}
            >
              {renderDetailCard()}
              {hightlight.aaa && (
                <div className="bg-[#333333] w-[8px] h-[8px] rounded-full absolute left-[19px] bottom-[14px] z-50"></div>
              )}
              {hightlight.aaa && (
                <div className="bg-[#333333] w-[8px] h-[8px] rounded-full absolute right-[19px] bottom-[14px] z-50"></div>
              )}
            </div>

            {hightlight.aaa && (
              <div
                style={{ width: itemW * (isDesktop ? 1.774 : 1.6) }}
                className="mt-[8px] pt-[36px] px-[16px] h-[144px] rounded-[14px] bg-[#FDFDFD] relative"
              >
                <div className="bg-[#333333] w-[8px] h-[8px] rounded-full absolute left-[19px] top-[14px]"></div>
                <div className="bg-[#333333] w-[8px] h-[8px] rounded-full absolute right-[19px] top-[14px]"></div>
                <div className="line-clamp-2 w-full overflow-hidden text-xs font-medium text-[#666666]">
                  {hightlight.aaa.title}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onGotoAsk(hightlight.aaa?._id ?? "");
                  }}
                  className="border rounded-[14px] h-[48px] mt-[12px] flex justify-between items-center px-[14px]"
                >
                  <div className="rounded-[12px] bg-[#F2F2F2] h-[28px] w-full mr-[10px] flex items-center px-[14px]">
                    <div className="mr-[6px] text-[#999999] line-clamp-1">
                      Go to ask
                    </div>
                    <div>
                      <MentionView user={hightlight.aaa.owner} type="message" />
                    </div>
                  </div>
                  <button className="w-[51px] h-[28px] bg-[#FF53B1] rounded-full text-white">
                    Go
                  </button>
                </div>
              </div>
            )}
            <div className="flex justify-center mt-[11px] mb-[88px]">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onLike();
                }}
                className="bg-[#FDFDFD] rounded-full w-[83px] h-[38px] flex items-center justify-center space-x-2"
              >
                <img src={likeIcon} alt="" />
                <div className="text-[#666666] text-[12px] font-medium">
                  {hightlight.like_count > 0 ? hightlight.like_count : "Like"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </Popup>
      {hightlight.type == "Image" ? (
        <div
          style={{ backgroundImage: `url(${hightlight.image})` }}
          className="relative bg-no-repeat bg-cover z-0"
        >
          <div className="absolute w-full h-full backdrop-blur-[10px] bg-black/70 z-[-10]"></div>
          <div
            style={{ backgroundImage: `url(${hightlight.image})` }}
            className={classNames(
              "bg-no-repeat bg-cover rounded-[14px] overflow-hidden z-20 relative",
              hightlight.aspect_ratio == "1:1" && "aspect-[1/1]",
              hightlight.aspect_ratio == "4:3" && "aspect-[4/3]",
              hightlight.aspect_ratio == "3:4" && "aspect-[3/4]"
            )}
          >
            {hightlight.link && (
              <img
                className="absolute bottom-2 right-2"
                src={image_link}
                alt=""
              />
            )}
          </div>

          {hightlight.description && (
            <div className="text-xs font-light px-3 text-white leading-[15px] pt-[6px] pb-[10px]">
              <span className="line-clamp-2">{hightlight.description}</span>
            </div>
          )}
        </div>
      ) : (
        <div
          className={classNames(
            "",
            hightlight.aspect_ratio == "1:1" && "aspect-[1/1]",
            hightlight.aspect_ratio == "4:3" && "aspect-[4/3]",
            hightlight.aspect_ratio == "3:4" && "aspect-[3/4]"
          )}
        >
          {renderCard()}
        </div>
      )}
    </div>
  );
}

function ImageDetialCard({
  hightlight,
  isDesktop,
}: {
  hightlight: Highlight;
  isDesktop: boolean;
}) {
  return (
    <div
      style={{ backgroundImage: `url(${hightlight.image})` }}
      className="relative bg-no-repeat bg-cover z-0"
    >
      <div className="absolute w-full h-full backdrop-blur-[10px] bg-black/70 z-[-10]"></div>
      <div
        style={{ backgroundImage: `url(${hightlight.image})` }}
        className={classNames(
          "bg-no-repeat bg-cover rounded-[14px] overflow-hidden z-20 relative",
          hightlight.aspect_ratio == "1:1" && "aspect-[1/1]",
          hightlight.aspect_ratio == "4:3" && "aspect-[4/3]",
          hightlight.aspect_ratio == "3:4" && "aspect-[3/4]"
        )}
      >
        {/* {hightlight.link && (
          <img className="absolute bottom-2 right-2" src={image_link} alt="" />
        )} */}
        <div className="absolute bottom-[13px] px-[16px] w-full">
          <HLDetialLinkView type="image" highlight={hightlight} />
        </div>
      </div>

      {hightlight.description && (
        <div
          className={classNames(
            "font-light px-3 text-white leading-[15px] pt-[6px] pb-[10px] h-[53px]",
            isDesktop ? "text-sm" : "text-xs"
          )}
        >
          <span className="line-clamp-2">{hightlight.description}</span>
        </div>
      )}
    </div>
  );
}

export function HLDetialLinkView({
  highlight,
  type,
}: {
  highlight: Highlight;
  type: "link" | "quote" | "image";
}) {
  return highlight.link.length > 0 ? (
    <div
      onClick={(e) => {
        window.open(highlight.link, "_blank");
        e.stopPropagation();
      }}
      className={classNames(
        "w-full px-[8px] flex items-center h-[28px] justify-between backdrop-blur-[10px] bg-[#FDFDFD]/30 rounded-full overflow-hidden",
        type == "link" && "border border-black bg-white",
        type == "quote" && "bg-white"
      )}
    >
      <div className="flex items-center">
        <div>
          <img src={linkIcon} alt="" />
        </div>
        <div className="text-[#333333] text-[14px] ml-[3px] line-clamp-1">
          {hostName(highlight.link)}
        </div>
      </div>
      <div>
        <img src={moreIcon} alt="" />
      </div>
    </div>
  ) : (
    <></>
  );
}

import QuoteAqua11 from "../../assets/hightlight/Quote-aqua-1_1.svg";
import QuoteAqua34 from "../../assets/hightlight/Quote-aqua-3_4.svg";
import QuoteAqua43 from "../../assets/hightlight/Quote-aqua-4_3.svg";

import QuoteBlast11 from "../../assets/hightlight/Quote-blast-1_1.svg";
import QuoteBlast34 from "../../assets/hightlight/Quote-blast-3_4.svg";
import QuoteBlast43 from "../../assets/hightlight/Quote-blast-4_3.svg";

import QuoteFever11 from "../../assets/hightlight/Quote-fever-1_1.svg";
import QuoteFever34 from "../../assets/hightlight/Quote-fever-3_4.svg";
import QuoteFever43 from "../../assets/hightlight/Quote-fever-4_3.svg";

import QuotePuzzle11 from "../../assets/hightlight/Quote-puzzle-1_1.svg";
import QuotePuzzle34 from "../../assets/hightlight/Quote-puzzle-3_4.svg";
import QuotePuzzle43 from "../../assets/hightlight/Quote-puzzle-4_3.svg";
import shave from "shave";
import fitty from "fitty";
import HighlightLink, { hostName } from "./HighlightLink";
import { LinkDetailCard } from "./LinkDetailCard";
import { Ama } from "../../models/ama.model";

function QuoteDetialCard({
  hightlight,
  isDesktop,
}: {
  hightlight: Highlight;
  isDesktop: boolean;
}) {
  function getBgImage() {
    let style = hightlight.style.toLocaleLowerCase();
    if (hightlight.aspect_ratio == "1:1") {
      if (style == "aqua") {
        return QuoteAqua11;
      } else if (style == "blast") {
        return QuoteBlast11;
      } else if (style == "fever") {
        return QuoteFever11;
      } else if (style == "puzzle") {
        return QuotePuzzle11;
      }
    } else if (hightlight.aspect_ratio == "3:4") {
      if (style == "aqua") {
        return QuoteAqua34;
      } else if (style == "blast") {
        return QuoteBlast34;
      } else if (style == "fever") {
        return QuoteFever34;
      } else if (style == "puzzle") {
        return QuotePuzzle34;
      }
    } else if (hightlight.aspect_ratio == "4:3") {
      if (style == "aqua") {
        return QuoteAqua43;
      } else if (style == "blast") {
        return QuoteBlast43;
      } else if (style == "fever") {
        return QuoteFever43;
      } else if (style == "puzzle") {
        return QuotePuzzle43;
      }
    }
  }

  const haveFontTextContentRef = useRef<HTMLDivElement | null>(null);
  const haveFontTextRef = useRef<HTMLDivElement | null>(null);

  const [layout, setLayout] = useState<string | null>(null);

  const [fontSize, setFontSize] = useState<string | null>(null);

  useEffect(() => {
    // 1. 创建一个隐藏的 span 获取到 fontSize
    if (haveFontTextContentRef.current?.offsetHeight) {
      if (haveFontTextRef.current?.offsetHeight) {
        const textElement = haveFontTextRef.current!;
        const textContent = haveFontTextContentRef.current!;
        if (textElement.offsetHeight > textContent.offsetHeight) {
          setLayout("justify-start");
          // shave(textElement, textContent.offsetHeight);
        } else {
          setLayout("justify-center");
        }
        shave(textElement, textContent.offsetHeight);
      }
    }
  }, [haveFontTextRef, hightlight, haveFontTextContentRef]);

  useEffect(() => {
    let ele = document.getElementById(`${"quote" + hightlight._id}`);
    let currentEle = document.getElementById(`${"quote_d" + hightlight._id}`);

    if (ele && currentEle) {
      let a = currentEle.clientWidth / ele.clientWidth;
      let font = a * parseInt(ele.style.fontSize);
      console.log(font);
      setFontSize(font + "px");
    }

    console.log(currentEle?.clientWidth);
  }, [
    document.getElementById(`${"quote_d" + hightlight._id}`),
    document.getElementById(`${"quote" + hightlight._id}`),
    hightlight,
  ]);

  return (
    <div
      id={`${"quote_d" + hightlight._id}`}
      style={{ backgroundImage: `url(${getBgImage()})` }}
      className={classNames(
        "w-full h-full flex flex-col px-[9px] bg-no-repeat bg-cover pt-[34px]",
        !hightlight.description &&
          !hightlight.title &&
          !hightlight.link &&
          "pb-[20px]"
      )}
    >
      <div
        ref={haveFontTextContentRef}
        className={classNames(
          "grow relative overflow-hidden flex flex-col mt-[10%]",
          hightlight.aspect_ratio == "3:4"
            ? "px-[20%]"
            : isDesktop
            ? "px-[6%]"
            : "px-[5%]",
          layout != null && layout
        )}
      >
        <div
          ref={haveFontTextRef}
          style={{
            fontSize: `${fontSize}`,
          }}
          className={classNames(
            "font-extrabold w-full tracking-[-0.4px] leading-[110%] text-wrap whitespace-pre-wrap",
            hightlight.text_align.toLocaleLowerCase() == "left" && "text-left",
            hightlight.text_align.toLocaleLowerCase() == "right" &&
              "text-right",
            hightlight.text_align.toLocaleLowerCase() == "center" &&
              "text-center"
          )}
        >
          {hightlight.quote_text}
        </div>
      </div>
      <div className="flex-shrink-0 h-max">
        <div
          className={classNames(
            "text-[rgba(0,0,0,0.5)] font-bold leading-[22px] line-clamp-1 px-[5px]",
            isDesktop ? "text-[20px] mb-[4px]" : "text-[12px]",
            !hightlight.description && !hightlight.link && "mb-[10px]"
          )}
        >
          {hightlight.title}
        </div>
        {hightlight.link && (
          <div className="mb-[40px] justify-self-end">
            <HLDetialLinkView type="quote" highlight={hightlight} />
          </div>
        )}
      </div>
    </div>
  );
}
