import { useEffect, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import PortalDesktop from "./portal_desktop";
import PortalMobile from "./portal_mobile";
import { User } from "../models/user.model";
import branch from "branch-sdk";

export default function Portal() {
  const size = useWindowSize();

  const [segmentReady, setSegmentReady] = useState(false);

  const [paged, setPaged] = useState(false);

  useEffect(() => {
    const win: any = window;

    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute("content", "#000000");
    }

    let timeout = setTimeout(() => {
      if (segmentReady == false) {
        setSegmentReady(true);
      }
    }, 5000);

    win.analytics.ready(function onReady() {
      if (!paged) {
        setPaged(true);
        win.analytics.page("AAAny Portal");
        console.log("analytics.ready");
      }
      setSegmentReady(true);
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [segmentReady, paged]);

  const [user, setUser] = useState<User>();

  return size.width > 768 ? (
    <PortalDesktop segmentReady={segmentReady} />
  ) : (
    <PortalMobile segmentReady={segmentReady} />
  );
}
