import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function AmaListLoading() {
  return (
    <SkeletonTheme
      baseColor="rgb(228,228,228)"
      highlightColor="rgb(233,233,233)"
    >
      <div className="relative w-full h-fit overflow-hidden flex flex-col px-6 space-y-0.5 flex-shrink-0 py-3">
        {/* 标题 */}
        <div className="flex space-x-4">
          <Skeleton width={24} height={24} circle />
          <Skeleton width={280} height={24} />
        </div>

        <div className="ml-[40px]">
          <Skeleton width={84} height={18} />
        </div>
        <div className="flex space-x-4 items-center">
          <Skeleton width={52} height={52} circle />
          <div className="flex flex-col">
            <Skeleton width={46} height={20} className="mt-2" />
            <Skeleton width={58} height={16} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
