import { useLocation, useParams } from "react-router-dom";
import logo from "../assets/login_logo.png";
import bottom_logo from "../assets/bottom_logo_2.svg";
import avatar from "../assets/avatar.png";
import host_bg from "../assets/host_bg.svg";
import host_tag from "../assets/host_tag.svg";
import aaa_bg from "../assets/aaa_bg.svg";
import d_btn_bg from "../assets/d_btn_bg.svg";
import header_logo from "../assets/header_logo.svg";
import icon_top from "../assets/icon_top.svg";

import {
  getMessages,
  getOneAma,
  getOneMessage,
  getOneMessageByShortId,
} from "../api/AmaApi";
import { useEffect, useRef, useState } from "react";
import ContentLoading from "../components/common/contentLoading/ContentLoading";
import LayoutWrapper from "../components/common/LayoutWrapper";
import { Ama } from "../models/ama.model";
import { limitStrNomal } from "../util/string";
import { Message } from "../models/message.model";
import classNames from "classnames";
import time from "../util/time";
import branch from "branch-sdk";
import notification from "../util/notifiaction";
import toast, { Toaster } from "react-hot-toast";
import useWindowSize from "../hooks/useWindowSize";
import { User } from "../models/user.model";
import server from "react-dom/server";
import AAAImageView from "../components/AAA/AAAImageView";
import MessageImageView from "../components/AAA/MessageImageView";
import AAARefers, { MessageRefers } from "../components/AAA/AAARefers";
// import snippet from "@segment/snippet";

export default function Root() {
  let { id, m, sid } = useParams();

  const [loading, setLoading] = useState(true);

  const [shareCardId, setShareCardId] = useState<string | undefined>(undefined);

  const [ama, setAma] = useState<Ama>();
  const [messages, setMessages] = useState<Message[]>();
  const [all, setAll] = useState(0);
  const [segmentReady, setSegmentReady] = useState(false);
  const { width, height } = useWindowSize();
  const [headerShow, setHeaderShow] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [logoRight, setLogoRight] = useState(0);
  const [downloadLeft, setDownloadLeft] = useState(0);

  useEffect(() => {
    const win: any = window;
    loadAJS();
  }, []);

  function getMessageData() {
    getMessages(id!).then((res) => {
      setMessages(res.list);
      setAll(res.count);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      getOneAma(id)
        .then((ama) => {
          setAma(ama);
          if (m) {
            getOneMessage(id!, m).then((res) => {
              if (cardDeleted(res)) {
                notification.error("Content Not Available.");
                getMessageData();
                return;
              }
              if (cardHaveDeleted(m!, res)) {
                notification.error("Content Not Available.");
              }
              setShareCardId(res._id);
              getMessages(id!, 5, true, `${res.createdAt}`).then(
                (beforeRes) => {
                  getMessages(id!, 4, false, `${res.createdAt}`).then(
                    (afterRes) => {
                      let messages = [...beforeRes.list, res, ...afterRes.list];
                      setMessages(messages);
                      setAll(afterRes.count);
                      setLoading(false);
                      scroll(res._id);
                    }
                  );
                }
              );
            });
          } else if (sid) {
            getOneMessageByShortId(id!, sid).then((res) => {
              if (cardDeleted(res)) {
                notification.error("Content Not Available.");
                getMessageData();
                return;
              }
              if (cardHaveDeleted(sid!, res)) {
                notification.error("Content Not Available.");
              }
              setShareCardId(res._id);
              getMessages(id!, 5, true, String(res.createdAt)).then(
                (beforeRes) => {
                  getMessages(id!, 4, false, `${res.createdAt}`).then(
                    (afterRes) => {
                      let messages = [
                        ...beforeRes.list.reverse(),
                        res,
                        ...afterRes.list,
                      ];
                      setMessages(messages);
                      setAll(afterRes.count);
                      setLoading(false);
                      scroll(res._id);
                    }
                  );
                }
              );
            });
          } else {
            getMessageData();
          }
        })
        .catch((e) => {
          setLoading(false);
          notification.error(e.data.message);
        });
    }
  }, [id, m, sid]);

  function scroll(m: string) {
    setTimeout(() => {
      let ele = document.querySelector(`#id${m}`);
      console.log(ele?.getBoundingClientRect());
      c.current?.scrollTo({ top: ele?.getBoundingClientRect().top });
      // document.querySelector(`#id${m}`)?.scrollIntoView({
      //   behavior: "smooth",
      // });
    }, 500);
  }

  function Top() {
    return (
      <div className="h-[46px] bg-white flex items-center justify-between px-[14px] w-full">
        <div>
          <img className="h-[40px]" src={logo} alt="" />
        </div>
        <div
          onClick={() => {
            toApp("Use App");
          }}
          className="px-[27px] h-[31px] bg-[#FF53B1] text-white flex items-center rounded-full text-[14px] font-bold"
        >
          Use App
        </div>
      </div>
    );
  }

  function loadAJS() {
    const win: any = window;
    setTimeout(() => {
      setSegmentReady(true);
    }, 5000);
    win.analytics.ready(function onReady() {
      setSegmentReady(true);
      win.analytics.page("AAA Detail Shared");
    });
  }

  function toApp(text: string) {
    const win: any = window;
    var anonymous_id = undefined;
    if (win.analytics != undefined) {
      anonymous_id = win.analytics.user().anonymousId();
      win.analytics.track("Button Clicked", {
        page_name: "AAA Detail Shared",
        button_text: text,
      });
    }

    var referrer_value = document.referrer;
    var aaa_id_value = id;
    var message_id = sid;
    var card_id = shareCardId;

    var linkData = {
      feature: "AAA Detail Shared",
      channel: "web",
      data: {
        aaa_id: aaa_id_value,
        referrer: referrer_value,
        anonymousId: anonymous_id,
        message_id: message_id,
        card_id: card_id,
      },
    };
    branch.link(linkData, function (err: any, link: string) {
      if (err == null) {
        window.location.href = link;
      } else {
        win.analytics.track("System Log", {
          error: String(err),
        });
        if (isAppleMobileDevice()) {
          window.location.href = "aaany://";
        } else {
          window.location.href =
            "https://apps.apple.com/app/aaany-ask-anyone-anything/id6450619356";
        }
      }
    });
  }

  function isAppleMobileDevice() {
    var userAgent = navigator.userAgent;
    var appleKeywords = ["iPhone", "iPad"];
    for (var i = 0; i < appleKeywords.length; i++) {
      if (userAgent.indexOf(appleKeywords[i]) !== -1) {
        return true;
      }
    }
  }

  function Bottom() {
    return (
      <div className="max-w-2xl fixed top-0 h-[108px]  bg-white w-full px-[17px] shadow-lg shadow-[rgba(0,0,0,0.15)] pt-2 z-50">
        <div className="flex items-center justify-between">
          <div className="">
            <div className="flex items-center ml-[3px]">
              <img className="h-[18px]" src={bottom_logo} alt="" />
            </div>
            {/* <div className="font-bold text-xs text-[#CFCFCF] scale-[0.84] ml-[-8px]">
              Ask Anyone Anything
            </div> */}
          </div>
          <div
            onClick={() => {
              toApp("Go to AAAny");
            }}
            className={classNames(
              "px-[14px] h-[31px] bg-[#FF53B1] text-white flex items-center rounded-full text-[14px] font-bold cursor-pointer",
              !segmentReady ? "hidden" : ""
            )}
          >
            Go to AAAny
          </div>
        </div>

        <div className="text-xs bg-gradient-to-r from-[#F6F6EE] to-[#FFF5FB] text-[#666666] px-3 py-2 rounded-md mt-[12px]">
          Download AAAny now and join the waiting list - we'll have you on board
          in 12 hours!
        </div>
      </div>
    );
  }

  function getTimeStr() {
    let timeStr = time(ama!.createdAt).fromNow();
    if (timeStr.indexOf("ago") == -1) {
      return `Posted on ${timeStr}`;
    } else {
      return `Posted ${timeStr}`;
    }
  }

  const aaaHeaderRef = useRef<HTMLDivElement>(null);

  function AAA() {
    return (
      <div>
        {width < 1186 && <Bottom />}
        <div
          style={{
            top: width < 1186 ? 108 : 0,
          }}
          className={classNames(
            "fixed max-w-2xl top-0 h-[53px] bg-[#D8E21A] w-full  z-50 flex justify-center items-center"
          )}
        >
          {headerShow && (
            <div className="h-[34px] bg-white rounded-full flex items-center  pl-1 pr-3 border-black border space-x-[8px]">
              <div className="w-[28px] h-[28px]">
                <Avatar user={ama?.owner} />
              </div>
              <div className="text-[#FF53B1] text-[14] font-semibold">
                {limitStrNomal(ama?.owner.name ?? "", 30)}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: width < 1186 ? 108 + 53 : 53,
          }}
          ref={aaaHeaderRef}
          className="bg-[#D8E21A] rounded-b-[24px] pb-[64px] px-[19px] relative pt-[10px] mt-[53px] z-[10]"
        >
          <div className="text-[12px] text-black/50">{getTimeStr()}</div>
          <div className="mt-[18px] mb-[28px] font-bold text-[24px] z-10">
            {ama?.title}
          </div>
          <div
            className="text-[14px] font-medium z-10"
            dangerouslySetInnerHTML={{
              __html: contentWithMention(
                ama?.content ?? "",
                ama?.mentioned_users ?? [],
                "aaa"
              ),
            }}
          ></div>
          {ama && ama.embedded_media && ama.embedded_media instanceof Array && (
            <AAAImageView c={c.current} aaa={ama} />
          )}
          <AAARefers aaa={ama} />
          <div
            style={{
              backgroundImage: `url(${aaa_bg})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundPositionY: "bottom",
            }}
            className="w-full h-full absolute left-0 bottom-0 z-[-1]"
          ></div>
          <div className="absolute left-[14px] bottom-[-17px] h-[34px] bg-white rounded-full flex items-center  pl-1 pr-3 border-black border space-x-[8px]">
            <div className="w-[28px] h-[28px]">
              <Avatar user={ama?.owner} />
            </div>
            <div className="text-[#FF53B1] text-[14] font-semibold">
              {limitStrNomal(ama?.owner.name ?? "", 30)}
            </div>
          </div>
        </div>
        <div className="mt-[41px] text-[13px] px-[17px] text-[#999999]">
          {/* {all} comments */}
        </div>
      </div>
    );
  }

  const c = useRef<HTMLDivElement | null>(null);

  const scrollContent = useRef<HTMLDivElement | null>(null);

  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    if (c.current) {
      let r = (width - c.current.offsetWidth - 135) / 4;
      let contentW = c.current.offsetWidth;
      setLogoRight(width / 2 + contentW / 2 + r - 100);

      setDownloadLeft((width - contentW) / 2 + contentW + 44);
    }
  }, [c, width]);

  return (
    <div
      ref={scrollContent}
      onScroll={(e) => {
        setHeaderShow(
          e.currentTarget.scrollTop > aaaHeaderRef.current!.offsetHeight
        );
        setScrollToBottom(
          e.currentTarget.clientHeight + e.currentTarget.scrollTop >=
            e.currentTarget.scrollHeight - 200
        );
      }}
      className="w-full flex justify-center relative max-w-full overflow-y-scroll bg-[#F2F2F2] h-screen"
    >
      {/* nav bar  */}
      {headerShow && width > 1186 && (
        <div
          className={classNames(
            "fixed top-0 h-[53px] bg-[#D8E21A] w-full flex justify-center items-center"
          )}
        ></div>
      )}
      {/* logo */}
      {width > 1168 && (
        <img
          onClick={() => {}}
          src={header_logo}
          width={135}
          style={{ right: logoRight }}
          className="mt-[12px] cursor-pointer fixed"
        />
      )}
      {width > 1186 && (
        <div style={{ left: downloadLeft }} className="fixed bottom-[46px]">
          <button
            onClick={() => {
              scrollContent.current?.scrollTo({ top: 0 });
            }}
            className="w-[66px] h-[66px] bg-[#FDFDFD] rounded-full border border-[#EDEDED] hover:bg-[#E2E2E2] active:bg-[#E2E2E2] flex justify-center items-center"
          >
            <img src={icon_top} alt="" />
          </button>

          {!scrollToBottom && (
            <button
              onClick={() => {
                toApp("Download AAAny");
              }}
              className="text-white bg-gradient-to-br from-[#FF76B0] to-[#FF53B1] w-[250px] h-[65px] rounded-full mt-[28px] flex items-center hover:from-[#FF97C4] hover:to-[#FF7BC2] active:from-[#F260A4] active:to-[#F23FA0]"
            >
              <img className="ml-[5px]" src={d_btn_bg} alt="" />
              <div className="ml-[10px] font-[800] text-[18px]">
                Download AAAny
              </div>
            </button>
          )}
        </div>
      )}
      <div className="flex flex-col w-full max-w-2xl flex-shrink-0">
        <div className="w-full bg-[#F2F2F2] flex flex-col">
          {/* <Bottom /> */}
          {/* <Top /> */}
          <div ref={c} className="w-full flex-grow parent">
            <ContentLoading contentType="Ama" loading={loading}>
              {ama ? (
                <>
                  <AAA />
                  <Messages
                    onClickDownLoad={() => {
                      toApp("Download AAAny");
                    }}
                    c={c.current}
                    messages={messages}
                  />
                </>
              ) : (
                <></>
              )}
            </ContentLoading>
          </div>
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: "6px",
            color: "white",
          },
        }}
      />
    </div>
  );
}

export function Avatar({ user }: { user?: User }) {
  const [err, setEerr] = useState(false);

  function getImageUrl() {
    if (
      user?.avatar?.startsWith("https://aaany.s3.ap-southeast-1.amazonaws.com/")
    ) {
      return avatar;
    } else {
      if (user?.avatar && user?.avatar?.length > 0) {
        return user.avatar;
      } else {
        return avatar;
      }
    }
  }

  return (
    <div className="w-full h-full rounded-full bg-gray-300">
      {err ? (
        <img className="w-full h-full rounded-full" src={avatar} />
      ) : (
        <img
          onError={() => {
            setEerr(true);
          }}
          className="w-full h-full rounded-full"
          src={getImageUrl()}
        />
      )}
    </div>
  );
}

export function MentionView({
  user,
  type = "message",
}: {
  user: User;
  type: "aaa" | "message";
}) {
  return (
    <span className="relative align-middle px-[2px]">
      <span
        className={classNames(
          "text-[14px] inline-flex top-[1px] items-center justify-start space-x-1 h-[16.5px] pl-[1px] pr-[6px] rounded-full w-fit",
          type == "aaa" ? "bg-[#F3FB58]" : "bg-[rgba(153,153,153,0.2)]"
        )}
      >
        <div className="w-[14.5px] h-[14.5px]">
          <Avatar user={user} />
        </div>
        <span className="text-[#666666] text-xs h-[14.5px] line-clamp-1">
          {user.name}
        </span>
      </span>
    </span>
  );
}

function contentWithMention(
  content: string,
  users: Array<User>,
  type: "message" | "aaa"
) {
  const parts = content.split(/(@\w+)/g);

  function B({ content }: { content: string }) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: content.replace(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
            '<a href="$1" target="_blank">$1</a>'
          ),
        }}
        className="text-[14px]"
      ></span>
    );
  }

  return parts
    .map((part, index) => {
      if (part.match(/@\w+/)) {
        const username = part.substring(1);

        let user: User | undefined = users.find((u: User) => {
          return u.username == username;
        });

        if (user) {
          const componentString = server.renderToString(
            <MentionView user={user} type={type} />
          );
          return componentString;
        } else {
          const componentString = server.renderToString(<B content={part} />);
          return componentString;
        }
      } else {
        const componentString = server.renderToString(<B content={part} />);
        return componentString;
      }
    })
    .join("");
}

function Messages({
  messages,
  c,
  onClickDownLoad,
}: {
  messages: Message[] | undefined;
  c: HTMLDivElement | null;
  onClickDownLoad: () => void;
}) {
  return (
    <div className="h-fit flex flex-col pb-[208px] pt-[10px] px-[12px] md:px-[0px] relative">
      {messages?.map((m, index) => {
        if (cardDeleted(m)) {
          return <div></div>;
        } else {
          return (
            <MessageCard
              onClickDownLoad={() => {
                onClickDownLoad();
              }}
              c={c}
              length={messages.length}
              index={index}
              key={m._id}
              message={m}
            />
          );
        }
      })}
    </div>
  );
}
function cardDeleted(message: Message) {
  let isDeleted = message.deleted;
  if (Array.isArray(message.children))
    for (const c of Array.from(message.children)) {
      isDeleted = isDeleted && c.deleted;
    }
  if (message.ref) isDeleted = isDeleted && message.ref.deleted;
  return isDeleted;
}

function cardHaveDeleted(mId: String, message: Message) {
  let isDeleted = message.deleted;
  if (isDeleted && mId == message.short_id) {
    return true;
  }
  if (Array.isArray(message.children))
    for (const c of Array.from(message.children)) {
      if (c.deleted && c.short_id == mId) {
        return true;
      }
    }
  if (message.ref) {
    if (message.ref.deleted && message.ref.short_id == mId) {
      return true;
    }
  }
  return false;
}

function MessageCard({
  message,
  length,
  index,
  c,
  onClickDownLoad,
}: {
  message: Message;
  length: number;
  index: number;
  c: HTMLDivElement | null;
  onClickDownLoad: () => void;
}) {
  const isHost = Boolean(message.flag & 4);
  const hostProps = isHost
    ? {
        style: {
          backgroundImage: `url(${host_bg})`,
          // borderRadius: "24px",
          backgroundRepeat: "no-repeat",
        },
      }
    : {};

  const messageImageViewRef = useRef<HTMLDivElement | null>(null);

  let haveImage =
    message.embedded_media &&
    message.embedded_media instanceof Array &&
    message.embedded_media.length > 0;

  let haveRefers =
    message.refers &&
    message.refers instanceof Array &&
    message.refers.length > 0;

  return (
    <div
      id={"id" + message._id}
      className="bg-white rounded-[24px] mb-[6px] relative"
    >
      {message.ref && <RefMessage message={message.ref!} />}
      <div
        {...hostProps}
        className={classNames(!message.ref && "rounded-[24px]")}
      >
        <div className="flex items-center px-[16px] pt-[16px]">
          <div className="w-[24px] h-[24px]">
            <Avatar user={message.author} />
          </div>
          <div className="text-[#999999] max-w-[50%] line-clamp-1 font-semibold ml-[8px] text-[12px]">
            {message.author?.name}
          </div>
          {isHost ? <img src={host_tag} /> : null}
          <div className="text-xs text-[#999999] ml-[8px]">
            {time(message.createdAt).fromNow()}
          </div>
        </div>
        <div
          className={classNames(
            "text-[14px]",
            message.content.length > 0 ? "mt-[12px]" : "mt-[0px]",
            !haveImage &&
              !haveRefers &&
              message.children &&
              message.children.length > 0 &&
              "pb-[20px]"
          )}
        >
          <div
            className={classNames(
              "px-[16px]",
              message.deleted && "text-[#999999]",
              !haveImage &&
                !haveRefers &&
                message.children &&
                message.children.length == 0 &&
                "pb-[20px]"
            )}
            dangerouslySetInnerHTML={{
              __html: contentWithMention(
                message.content ?? "",
                message.mentioned_users,
                "message"
              ),
            }}
          ></div>
        </div>
        {haveImage && (
          <div ref={messageImageViewRef} className="mt-[12px] pb-[20px]">
            <MessageImageView c={c} message={message} />
          </div>
        )}
        <MessageRefers message={message} />
      </div>
      {message.children && (
        <div>
          {message.children.map((message) => {
            return <Answer content={c} key={message._id} message={message} />;
          })}
        </div>
      )}

      {index == length - 1 && (
        <div className="absolute top-0 left-0 w-full h-[478px] z-[40]">
          <div className="bg-gradient-to-b from-[rgba(242,242,242,0)] to-[#F2F2F2] h-[64px]"></div>
          <div className="bg-[#F2F2F2] w-full h-full flex flex-col items-center">
            <div className="text-[#6D6166] font-bold text-[15px] px-[97px] mt-[73px]">
              Thank you for reading this far! There are many more replies in
              this AMA. Want to find out all the answers? Have a question to
              ask? Want to start your own AMA? Download now and join the waiting
              list - we'll have you on board in 12 hours!
            </div>
            <button
              onClick={() => {
                onClickDownLoad();
              }}
              className="text-white bg-gradient-to-br from-[#FF76B0] to-[#FF53B1] w-[242px] h-[65px] rounded-full mt-[43px] flex items-center hover:from-[#FF97C4] hover:to-[#FF7BC2] active:from-[#F260A4] active:to-[#F23FA0]"
            >
              <img className="ml-[5px]" src={d_btn_bg} alt="" />
              <div className="ml-[10px] font-[800] text-[18px]">
                Download AAAny
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function RefMessage({ message }: { message: Message }) {
  return (
    <div className="px-[16px] border-b pb-[10px] text-[#999999] mt-[16px] overflow-hidden text-[14px]">
      <span className="line-clamp-2">
        <span className="font-medium text-[12px]">{message.author.name}:</span>
        <span dangerouslySetInnerHTML={{ __html: message.content }}></span>
      </span>
    </div>
  );
}

function Answer({
  message,
  content,
}: {
  message: Message;
  content: HTMLDivElement | null;
}) {
  return (
    <div
      style={{
        backgroundImage: `url(${host_bg})`,
        backgroundRepeat: "no-repeat",
      }}
      className="border-t"
    >
      <div>
        <div className="flex items-center px-[16px] pt-[16px]">
          <div className="w-[24px] h-[24px]">
            <Avatar user={message.author} />
          </div>

          <div className="text-[#999999] max-w-[50%] line-clamp-1 font-semibold ml-[8px] text-[12px]">
            {message.author?.name}
          </div>
          <img src={host_tag} alt="" />
          <div className="text-xs text-[#999999] ml-[8px]">
            {time(message.createdAt).fromNow()}
          </div>
        </div>
        <div
          className={classNames(
            "text-[14px] pb-[20px]",
            message.children ? "border-b" : "",
            message.content.length > 0 ? "mt-[12px]" : "mt-[0px]"
          )}
        >
          <div
            className={classNames(
              "px-[16px]",
              message.deleted && "text-[#999999]"
            )}
            dangerouslySetInnerHTML={{
              __html: contentWithMention(
                message.content,
                message.mentioned_users,
                "message"
              ),
            }}
          ></div>
          {message.embedded_media &&
            message.embedded_media instanceof Array &&
            message.embedded_media.length > 0 && (
              <div className="mt-[12px]">
                <MessageImageView c={content} message={message} />
              </div>
            )}
          <MessageRefers message={message} />
        </div>
      </div>
      {/* {message.additionals && (
        <div>
          {message.additionals.map((add, idx) => {
            return (
              <div key={idx} className=" px-[16px]">
                <div className="border-t"></div>
                <div
                  className="my-[8px] text-[14px]"
                  dangerouslySetInnerHTML={{ __html: add.content }}
                ></div>
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
}
