import classNames from "classnames";
import { Highlight } from "../../api/AmaApi";
import { useEffect, useRef, useState } from "react";

import Twilight3_4 from "../../assets/hightlight/3_4Twilight.svg";
import Twilight4_3 from "../../assets/hightlight/4_3Twilight.svg";
import Twilight1_1 from "../../assets/hightlight/1_1Twilight.svg";

import Spring3_4 from "../../assets/hightlight/3_4Spring.svg";
import Spring4_3 from "../../assets/hightlight/4_3Spring.svg";
import Spring1_1 from "../../assets/hightlight/1_1Spring.svg";

import Mist3_4 from "../../assets/hightlight/3_4Mist.svg";
import Mist4_3 from "../../assets/hightlight/4_3Mist.svg";
import Mist1_1 from "../../assets/hightlight/1_1Mist.svg";

import Butter3_4 from "../../assets/hightlight/3_4Butter.svg";
import Butter4_3 from "../../assets/hightlight/4_3Butter.svg";
import Butter1_1 from "../../assets/hightlight/1_1Butter.svg";
import shave from "shave";

export default function TextCard({ hightlight }: { hightlight: Highlight }) {
  function getBgImage() {
    if (hightlight.style == "Spring") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Spring1_1;
        case "3:4":
          return Spring3_4;
        case "4:3":
          return Spring4_3;
      }
    } else if (hightlight.style == "Butter") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Butter1_1;
        case "3:4":
          return Butter3_4;
        case "4:3":
          return Butter4_3;
      }
    } else if (hightlight.style == "Mist") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Mist1_1;
        case "3:4":
          return Mist3_4;
        case "4:3":
          return Mist4_3;
      }
    } else if (hightlight.style == "Twilight") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Twilight1_1;
        case "3:4":
          return Twilight3_4;
        case "4:3":
          return Twilight4_3;
      }
    }
  }

  function getUnderlineColor() {
    if (hightlight.style == "Spring") {
      return "decoration-[#79C697]";
    } else if (hightlight.style == "Butter") {
      return "decoration-[#F4D050]";
    } else if (hightlight.style == "Mist") {
      return "decoration-[#B7B5AD]";
    } else if (hightlight.style == "Twilight") {
      return "decoration-[#BAB6F7]";
    }
  }

  const textRef = useRef<HTMLDivElement | null>(null);
  const textContentRef = useRef<HTMLDivElement | null>(null);

  const [bgUrl, setBgUrl] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (bgUrl && textRef.current && textContentRef.current) {
      shave(textRef.current, textContentRef.current.offsetHeight - 24 - 9 - 10);
    }
  }, [textRef, hightlight, textContentRef, bgUrl]);

  return (
    <div
      ref={textContentRef}
      style={{ backgroundImage: `url(${bgUrl})` }}
      className="w-full h-full flex flex-col justify-center px-3 bg-no-repeat bg-cover pb-[9px]"
    >
      <img
        onLoad={() => {
          setBgUrl(getBgImage);
        }}
        src={getBgImage()}
        alt=""
        className="absolute invisible"
      />
      {bgUrl && (
        <div
          ref={textRef}
          className={classNames(
            "mt-6 max-h-fit overflow-hidden w-full underline-offset-2 underline text-xs font-[500] leading-normal whitespace-pre-wrap",
            getUnderlineColor(),
            hightlight.text_align.toLocaleLowerCase() == "left" && "text-left",
            hightlight.text_align.toLocaleLowerCase() == "right" &&
              "text-right",
            hightlight.text_align.toLocaleLowerCase() == "center" &&
              "text-center"
          )}
        >
          {hightlight.text}
        </div>
      )}
    </div>
  );
}

export function HLDetailTextCard({
  hightlight,
  isDesktop,
}: {
  hightlight: Highlight;
  isDesktop: boolean;
}) {
  function getBgImage() {
    if (hightlight.style == "Spring") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Spring1_1;
        case "3:4":
          return Spring3_4;
        case "4:3":
          return Spring4_3;
      }
    } else if (hightlight.style == "Butter") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Butter1_1;
        case "3:4":
          return Butter3_4;
        case "4:3":
          return Butter4_3;
      }
    } else if (hightlight.style == "Mist") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Mist1_1;
        case "3:4":
          return Mist3_4;
        case "4:3":
          return Mist4_3;
      }
    } else if (hightlight.style == "Twilight") {
      switch (hightlight.aspect_ratio) {
        case "1:1":
          return Twilight1_1;
        case "3:4":
          return Twilight3_4;
        case "4:3":
          return Twilight4_3;
      }
    }
  }

  function getUnderlineColor() {
    if (hightlight.style == "Spring") {
      return "decoration-[#79C697]";
    } else if (hightlight.style == "Butter") {
      return "decoration-[#F4D050]";
    } else if (hightlight.style == "Mist") {
      return "decoration-[#B7B5AD]";
    } else if (hightlight.style == "Twilight") {
      return "decoration-[#BAB6F7]";
    }
  }

  function getHoverToColor() {
    if (hightlight.style == "Spring") {
      return "to-[#B8DEA2]";
    } else if (hightlight.style == "Butter") {
      return "to-[#FFEA7F]";
    } else if (hightlight.style == "Mist") {
      return "to-[#D8D8D4]";
    } else if (hightlight.style == "Twilight") {
      return "to-[#DDDBF8]";
    }
  }

  const textRef = useRef<HTMLDivElement | null>(null);
  const textContentRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   if (textRef.current && textContentRef.current) {
  //     shave(
  //       textRef.current,
  //       textContentRef.current.offsetHeight -
  //         24 -
  //         9 -
  //         10 -
  //         (isDesktop ? 40 : 20)
  //     );
  //   }
  // }, [textRef, hightlight, textContentRef]);

  return (
    <div
      ref={textContentRef}
      style={{ backgroundImage: `url(${getBgImage()})` }}
      className="w-full h-full flex flex-col justify-center  relative bg-no-repeat bg-cover pb-[9px]"
    >
      <div
        className={classNames(
          "absolute h-[48px] z-[20] w-[99%] bottom-[32px] bg-gradient-to-b from-transparent left-[0.5%]",
          getHoverToColor()
        )}
      ></div>
      <div
        ref={textRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={classNames(
          "max-h-full px-[20px] overflow-x-hidden overflow-y-auto w-full underline-offset-2 underline font-[500] leading-normal whitespace-pre-wrap mb-[24px] pb-[32px] relative",
          getUnderlineColor(),
          hightlight.text_align.toLocaleLowerCase() == "left" && "text-left",
          hightlight.text_align.toLocaleLowerCase() == "right" && "text-right",
          hightlight.text_align.toLocaleLowerCase() == "center" &&
            "text-center",
          isDesktop ? "text-[16px] mt-16" : "text-[14px] mt-12"
        )}
      >
        {hightlight.text}
      </div>
    </div>
  );
}
